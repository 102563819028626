import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import Reloj from "../componentes/Dashboard/reloj";
import ContadorHuespedes from "../componentes/Dashboard/counterDashboard";
import PrincipalDB from "../componentes/Dashboard/principalDB";
import Reservaciones from "../componentes/Dashboard/reservaciones";
import Sumario from "../componentes/Dashboard/sumario";
import ParkingSystem from "../componentes/Dashboard/parkingSystem";
import LateralDashboard from "../componentes/Dashboard/accionRapida_dashboard";

import "./Dashboard_style.css";

function Dashboard() {
  const [seccionActual, setSeccionActual] = useState("reservaciones");
  const navigate = useNavigate(); // Crea la instancia de navigate

  const handleLogout = () => {
    // Muestra el popup de confirmación
    const confirmLogout = window.confirm("¿Estás seguro de que quieres cerrar sesión?");
    if (confirmLogout) {
      localStorage.removeItem('isAuthenticated');
      navigate('/login'); // Redirige a la página de login
    }
  };

  return (
    <div className="Dashboart_page">
      {/* Encabezado */}
      <div className="encabezado_DB">
        <img
          className="logoDB"
          src="./imagenes/logosanagustin_verde.svg"
          alt="Logo San Agustín"
        />
        <h1>Sistema de recepción</h1>
        <div className="horayFechaDB">
          <h4>Bienvenido(a)</h4>
          <Reloj />
        </div>
      </div>

      {/* Contenido principal */}
      <section className="contenido_DB">
        {/* Menú y Contenido dentro de principal_DB */}
        <div className="principal_DB">
          {/* Menú de navegación */}
          <nav className="menu_DB">
            <ul>
            <li onClick={() => setSeccionActual("panelprincipal")}
            className={seccionActual === "panelprincipal" ? "activo" : ""}
            >
                Panel principal
              </li>
              <li onClick={() => setSeccionActual("reservaciones")}
              className={seccionActual === "reservaciones" ? "activo" : ""}
              >
                Reservaciones
              </li>
              <li onClick={() => setSeccionActual("estacionamiento")}
              className={seccionActual === "estacionamiento" ? "activo" : ""}
              >
                Estacionamiento
              </li>
              <li onClick={() => setSeccionActual("resumen")}
              className={seccionActual === "resumen" ? "activo" : ""}
              >
                Resumen Mensual
              </li>
              <li>
              <button onClick={handleLogout} className="bototCerrarSesion">Cerrar sesión</button>
              </li>
            </ul>
          </nav>

          {/* Contenido dinámico */}
          <div className="contenido_seccion">
            {seccionActual === "reservaciones" && (
              <div>
                <h1>Reservaciones</h1>
                <ContadorHuespedes/>
                <Reservaciones/>
              </div>
            )}
            {seccionActual === "estacionamiento" && (
              <div>
                <h1>Estacionamiento</h1>
                <ParkingSystem/>
              </div>
            )}
            {seccionActual === "resumen" && (
              <div>
                <h1>Resumen Mensual</h1>
                <Sumario/>
              </div>
            )}
            {seccionActual === "panelprincipal" && (
              <div>
                <h1>Panel pricipal</h1>
                <ContadorHuespedes/>
                <PrincipalDB/>
              </div>
            )}
          </div>
        </div>
        <LateralDashboard/>
      </section>
      <section className="PantallaTamañoMal">
        <img src="" alt=""></img>
        <h1>REGRESA A TAMAÑO COMPLETO</h1>
      </section>
    </div>
  );
}

export default Dashboard;
