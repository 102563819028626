import "./principalDB_style.css";
import { db, collection, getDocs, query, where, orderBy } from "../firebase";
import { Timestamp } from "firebase/firestore";
import React, { useState, useEffect } from "react";

function Sumario() {
  const [guests, setGuests] = useState([]); // Para almacenar los datos
  const [selectedGuest, setSelectedGuest] = useState(null); // Para almacenar el huésped seleccionado

  useEffect(() => {
    const today = new Date();
    const thirtyOneDaysAgo = new Date(today);
    thirtyOneDaysAgo.setDate(today.getDate() - 31);
  
    const startDate = Timestamp.fromDate(thirtyOneDaysAgo);
    const endDate = Timestamp.fromDate(today);
  
    const checkinsRef = collection(db, "checkins");
    const checkoutsRef = collection(db, "checkouts");
  
    const checkinQuery = query(
      checkinsRef,
      where("timestamp", ">=", startDate),
      where("timestamp", "<=", endDate),
      orderBy("timestamp", "desc")
    );
  
    const checkoutQuery = query(
      checkoutsRef,
      where("timestamp", ">=", startDate),
      where("timestamp", "<=", endDate),
      orderBy("timestamp", "desc")
    );
  
    const fetchData = async () => {
      try {
        const [checkinSnapshot, checkoutSnapshot] = await Promise.all([
          getDocs(checkinQuery),
          getDocs(checkoutQuery),
        ]);
  
        // Crear un diccionario para los checkouts
        const checkoutMap = {};
        checkoutSnapshot.forEach((doc) => {
          const data = doc.data();
          const key = `${data.nombreHuesped}-${data.habitacion}`;
          checkoutMap[key] = data.timestamp;
        });
  
        const guestsList = [];
  
        checkinSnapshot.forEach((doc) => {
          const checkinData = doc.data();
          const { nombreHuesped, habitacion, timestamp, costo, tipoHabitacion, ninos, adultos, phoneHuesped, emailHuesped, precioTotal, paymentMethod } = checkinData;
  
          const key = `${nombreHuesped}-${habitacion}`;
          const checkoutDate = checkoutMap[key] || null;
  
          const checkInDateFormatted = timestamp
            ? new Date(timestamp.seconds * 1000).toLocaleString()
            : "";
  
          let totalCost = costo;
  
          if (checkoutDate) {
            const checkOutDate = new Date(checkoutDate.seconds * 1000);
            const nightsStayed = Math.ceil(
              (checkOutDate - new Date(timestamp.seconds * 1000)) / (1000 * 3600 * 24)
            );
  
            if (nightsStayed > 1) {
              totalCost += (nightsStayed - 1) * costo;
            }
          }
  
          guestsList.push({
            nombreHuesped,
            habitacion,
            tipoHabitacion,
            checkInDate: checkInDateFormatted,
            checkoutDate: checkoutDate
              ? new Date(checkoutDate.seconds * 1000).toLocaleString()
              : "-",
            totalCost,
            ninos,
            adultos,
            phoneHuesped,
            emailHuesped,
            precioTotal,
            paymentMethod,
          });
        });
  
        setGuests(guestsList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  // Función para manejar la impresión
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; }
            th, td { padding: 8px; text-align: left; border: 1px solid #ddd; }
            th { background-color: #f2f2f2; }
            .table-container { margin: 20px; }
          </style>
        </head>
        <body>
          <h2>Lista de Huéspedes</h2>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Habitación</th>
                  <th>Cuenta</th>
                </tr>
              </thead>
              <tbody>
                ${guests.map(guest => `
                  <tr>
                    <td>${guest.nombreHuesped}</td>
                    <td>${guest.tipoHabitacion}</td>
                    <td>${guest.checkInDate}</td>
                    <td>${guest.checkoutDate}</td>
                    <td>${guest.habitacion}</td>
                    <td>$${guest.totalCost}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="PagePrinc_DB">
      {/* Tabla de huéspedes */}
      <div className="lista-huespedes">
        <div className="ecabezadoPrincipal">
          <h3>ENERO</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Habitación</th>
              <th>Cuenta</th>
            </tr>
          </thead>
          <tbody>
            {guests.map((guest, index) => (
              <tr
                key={index}
                onClick={() => setSelectedGuest(guest)} // Establece el huésped seleccionado
                style={{ cursor: "pointer" }} // Cambia el cursor al pasar sobre la fila
              >
                <td>{guest.nombreHuesped}</td>
                <td className={`etiqueta ${guest.tipoHabitacion.toLowerCase()}`}>{guest.tipoHabitacion}</td>
                <td>{guest.checkInDate}</td>
                <td>{guest.checkoutDate}</td>
                <td>{guest.habitacion}</td>
                <td>${guest.precioTotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mostrar detalles del huésped seleccionado */}
      {selectedGuest && (
        <div className="modal">
          <div className="modal-content">
            <h3>Información Completa del Huésped</h3>
            <p><strong>Nombre:</strong> {selectedGuest.nombreHuesped}</p>
            <p><strong>Habitación:</strong> {selectedGuest.habitacion}</p>
            <p><strong>Tipo de Habitación:</strong> {selectedGuest.tipoHabitacion}</p>
            <p><strong>Niños:</strong> {selectedGuest.ninos}</p>
            <p><strong>Adultos:</strong> {selectedGuest.adultos}</p>
            <p><strong>Nombre del huésped:</strong> {selectedGuest.nombreHuesped}</p>
            <p><strong>Teléfono del huésped:</strong> {selectedGuest.phoneHuesped}</p>
            <p><strong>Email del huésped:</strong> {selectedGuest.emailHuesped}</p>
            <p><strong>Costo por noche:</strong> ${selectedGuest.totalCost}</p>
            <p><strong>Precio Total:</strong> ${selectedGuest.precioTotal}</p>
            <p><strong>Método de pago:</strong> {selectedGuest.paymentMethod}</p>
            <button onClick={() => setSelectedGuest(null)}>Cerrar</button>
          </div>
        </div>
      )}

      {/* Botón para imprimir */}
      <button className="btn-nuevo" onClick={handlePrint}>Imprimir</button>
    </div>
  );
}

export default Sumario;
