import "./promocionMarina_style.css";
import { Link } from "react-router-dom";

function PromocionMarina() {
  return (
    <div className="contenedorPRomocionMarina">
      <div className="cont_fotoMarina">
        <img
          className="imgMArina"
          alt="Foto de Marina 33"
          src="./imagenes/domingomarina2.jpg"
        />
      </div>
      <div className="textoMarina">
        <a>
          <h1>10%</h1> 
        </a>
        <a>
        <h2>DE DESCUENTO</h2>
        <h2>PARA NUESTROS</h2>
        <h2>HUESPEDES</h2>
        </a>
        
      </div>
      <div className="logoyredesMarina">
        <img
          className="logoMarina"
          src="./imagenes/MARINALOGOHORIZONTAL.png"
          alt="Marina 33"
        />
        <div className="redesMarina">
          <Link to="" target="blank">
            <img className="logoRSMarina" src="./imagenes/icono_IG.png" alt="Instagram" />
          </Link>
          <Link to="" target="blank">
            <img className="logoRSMarina" src="./imagenes/iconofb.png" alt="Facebook" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PromocionMarina;
