import React, { useState } from "react";
import "./rooms_style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { db } from "../componentes/firebase"; // Asegúrate de tener la configuración de Firebase aquí
import { collection, addDoc } from "firebase/firestore";

function Rooms() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [reservationStep, setReservationStep] = useState(1);
  const [currentRoom, setCurrentRoom] = useState(null); // Nueva variable para almacenar la habitación seleccionada
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date_in: "",
    date_out: "",
    guests: 1,
  });

  // Lista de habitaciones
  const roomsList = [
    {
      name: "Queen Standard",
      price: 1500,
      capacity: 2,
      beds: "1 Queen Size",
      imageUrl:
        "./imagenes/galeria/462142323_1299055784582888_2105250226440394151_n.jpg",
      page: "/Habitaciones/HabitacionQueenStandard",
      paymentUrl: "https://buy.stripe.com/test_9AQeXXcAT2NXbWEcMM",
    },
    {
      name: "Doble Queen",
      price: 1600,
      capacity: 4,
      beds: "2 Queen Size",
      imageUrl:
        "./imagenes/galeria/462149205_1299055804582886_1006545949177207373_n.jpg",
      page: "/Habitaciones/HabitacionDobleQueen",
      paymentUrl: "https://buy.stripe.com/test_7sIcPP6cvgEN2m4fYZ"
    },
    {
      name: "Familiar",
      price: 1800,
      capacity: 6,
      beds: "2 Queen + 2 Individuales",
      imageUrl:
        "./imagenes/galeria/462227730_1299055814582885_697985619645913819_n.jpg",
      page: "/Habitaciones/HabitacionFamiliar",
      paymentUrl: "https://buy.stripe.com/test_8wMaHH30jfAJ4uc146"
    },
    {
      name: "King",
      price: 1500,
      capacity: 2,
      beds: "1 King Size",
      imageUrl:
        "./imagenes/galeria/469703372_1348470862974713_3374909509485950929_n.jpg",
      page: "/Habitaciones/HabitacionKing",
      paymentUrl: "https://buy.stripe.com/test_5kAg21fN5cox5yg28b",
    },
    {
      name: "Doble Matrimonial",
      price: 1600,
      capacity: 4,
      beds: "2 Matrimonial",
      imageUrl:
        "./imagenes/galeria/IMG_8991.JPG",
      page: "/Habitaciones/habitacionDobleMatrimonial",
      paymentUrl: "https://buy.stripe.com/test_6oE17744n3S19Ow6os",
    },
  ];

  // Función para abrir el popup
  const openPopup = (room) => {
    setCurrentRoom(room); // Guardar la habitación seleccionada
    setPopupVisible(true);
  };

  // Función para cerrar el popup
  const closePopup = () => {
    setPopupVisible(false);
    setReservationStep(1); // Reiniciar al cerrar
    setCurrentRoom(null); // Limpiar la habitación seleccionada
  };

  // Función para manejar cambios en el formulario
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validaciones
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/; // Ejemplo: números de 10 dígitos
    return phoneRegex.test(phone);
  };

  // Manejo del envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, phone } = formData;

    if (!validateEmail(email)) {
      alert("Por favor, ingresa un correo electrónico válido.");
      return;
    }

    if (!validatePhone(phone)) {
      alert("Por favor, ingresa un número de teléfono válido (10 dígitos).");
      return;
    }

    // Avanzar a la siguiente etapa
    setReservationStep(2);
  };

  // Subir la información a Firestore cuando el usuario hace clic en el paso 2
  const handlePaymentRedirect = () => {
    // Guardar la información en Firestore
    addDoc(collection(db, "reservations"), formData)
      .then(() => {
        // Una vez que se ha subido la información, redirigir al enlace de pago
        window.open(currentRoom.paymentUrl, "_blank");
        setReservationStep(3); // Cambiar al paso 3
      })
      .catch((error) => {
        console.error("Error al guardar la reservación: ", error);
        alert("Ocurrió un error al guardar tu reservación.");
      });
  };
  return (
    <div className="Habitaciones">
      <header className="encabezadoHabitaciones">
        <img
          className="logoHabitaciones"
          src="./imagenes/logosanagustinBLANCO.png"
          alt="Hotel Real San Agustín "
        />
        <h1>HABITACIONES</h1>
        <img
          className="separadorRooms"
          src="./imagenes/separador_hornamental.svg"
        />
      </header>
      <div className="divisionRooms">
        <hr />
      </div>
      <div className="contenedorHabitaciones">
        {roomsList.map((room, index) => (
          <div key={index} className="HabitacionOpcion">
            <div className="infoRoom">
              <h3>Habitación</h3>
              <h2>{room.name}</h2>
              <h3 className="precioHabitacion">${room.price}</h3>
              <div className="infoHabitacionIConos">
                <div className="capacidad">
                  <img
                    src="./imagenes/iconos/personas.svg"
                    className="iconoRoom"
                    alt="Personas"
                  />
                  <div className="textoCapacidad">
                    <h4>Capacidad</h4>
                    <h3>{room.capacity} personas</h3>
                  </div>
                </div>
                <div className="camas">
                  <img
                    src="./imagenes/iconos/camas.svg"
                    className="iconoRoom iconoCama"
                    alt="Camas"
                  />
                  <div className="textoCapacidad">
                    <h4>Cama(s)</h4>
                    <h3>{room.beds}</h3>
                  </div>
                </div>
              </div>
              <div className="botonesRooms">
                <button
                  className="botonReservar"
                  onClick={() => openPopup(room)}
                >
                  Reservar
                </button>
                <Link to={room.page}>
                  <button className="botonInfoRooms">Ver detalles</button>
                </Link>
              </div>
            </div>
            <div className="contImg_room">
              <img
                className="imgPrinc_roon"
                alt={`Habitación ${room.name}`}
                src={room.imageUrl}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Popup */}
      {popupVisible && (
        <div className="popupOverlay">
          <div className="popupContent">
            {reservationStep === 1 && (
              <>
                <div className="cont_encabezadoReservaciones">
                  <h2>RESERVACIONES</h2>
                  <img
                    className="logoFormulario"
                    src="./imagenes/logosanagustin_verde.svg"
                    alt="Reservaciones"
                  />
                </div>
                <div className="separadorFormCheckin">
                  <div className="contImg_FormCheckin">
                    <div className="pasoCheckin">
                      <p>1/3</p>
                    </div>
                    <img
                      className="imagenCheckin"
                      src="./imagenes/recepcionista.svg"
                    />
                  </div>
                  <form className="formularioCheckin" onSubmit={handleSubmit}>
                    <div className="fechasCheckin">
                      <a>
                        <label htmlFor="dates">Llegada:</label>
                        <input
                          type="date"
                          id="date_in"
                          name="date_in"
                          value={formData.date_in}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                      <a>
                        <label htmlFor="dates">Salida:</label>
                        <input
                          type="date"
                          id="date_out"
                          name="date_out"
                          value={formData.date_out}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                    </div>
                    <div className="infohuespedes_Checkin">
                      <a>
                        <label htmlFor="NumerodeHuespedes">Huespedes:</label>
                        <input
                          type="number"
                          id="hosts"
                          name="guests"
                          value={formData.guests}
                          onChange={handleFormChange}
                          required
                          min="1"
                          max="7"
                        />
                      </a>
                      <a>
                        <label htmlFor="name">Nombre:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                    </div>

                    <div className="contacto_Checkin">
                      <a>
                        <label htmlFor="email">Correo Electrónico:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                      <a>
                        <label htmlFor="phone">Teléfono:</label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                    </div>

                    <button type="submit">siguiente</button>
                  </form>
                  <button className="closePopup" onClick={closePopup}>
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </>
            )}
            {reservationStep === 2 && (
              <>
                <div className="cont_encabezadoReservaciones">
                  <h2>RESERVACIONES</h2>
                  <img
                    className="logoFormulario"
                    src="./imagenes/logosanagustin_verde.svg"
                    alt="Reservaciones"
                  />
                </div>
                <div className="separadorFormCheckin">
                  <div className="contImg_FormGracias">
                    <div className="pasoCheckin paso_Gracias">
                      <p>2/3</p>
                    </div>
                    <img
                      className="imagenGracias"
                      src="./imagenes/modelotelefono.svg"
                    />
                  </div>
                  <div className="texto_GraciasReserva">
                    <h1 className="texto_h2gracias">Confirmación</h1>
                    <p>
                      Para completar tu reservación, es necesario realizar un
                      adelanto del 50% del costo de una noche.
                    </p>
                    <p className="texto_avisoPopUp">
                      No te preocupes, si llegas a cancelar o no puedes asistir,
                      el monto se mantendrá acreditado para una futura
                      reservación.
                    </p>
                    <button
                     onClick={handlePaymentRedirect}
                    >
                      Confirmar y pagar
                    </button>
                  </div>
                  <button className="closePopup" onClick={closePopup}>
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </>
            )}
            {reservationStep === 3 && (
              <>
                <div className="cont_encabezadoReservaciones">
                  <h2>RESERVACIONES</h2>
                  <img
                    className="logoFormulario"
                    src="./imagenes/logosanagustin_verde.svg"
                    alt="Reservaciones"
                  />
                </div>
                <div className="separadorFormCheckin">
                  <div className="contImg_FormGracias">
                    <div className="pasoCheckin paso_Gracias">
                      <p>3/3</p>
                    </div>
                  </div>
                  <div className="texto_GraciasReserva">
                    <h1 className="texto_h2gracias">
                      Tu reservación está casi lista
                    </h1>
                    <p>
                      Es todo en esta ventana continua procediendo con tu pago a
                      traves de stripe con el fin de hacerlo
                    </p>
                    <p className="texto_avisoPopUp">
                      No te preocupes, si llegas a cancelar o no puedes asistir,
                      el monto se mantendrá acreditado para una futura
                      reservación.
                    </p>
                  </div>
                  <button className="closePopup" onClick={closePopup}>
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Rooms;
