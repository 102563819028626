import React, { useState, useEffect } from "react";
import { db, collection, getDocs, query, where, orderBy, doc, deleteDoc, setDoc } from "../firebase";
import { Timestamp } from "firebase/firestore"; // Importar Timestamp
import "./principalDB_style.css";

function PrincipalDB() {
  const [guests, setGuests] = useState([]); // Para almacenar los datos
  const [selectedGuest, setSelectedGuest] = useState(null); // Para almacenar el huésped seleccionado
  const [isModalOpen, setIsModalOpen] = useState(false); // Para manejar la visibilidad del popup
  const [paymentMethod, setPaymentMethod] = useState(""); // Para almacenar el método de pago seleccionado
  const today = new Date();

  // Función para calcular el número de noches completas desde el check-in hasta la fecha actual
  function calcularNochesCompletas(checkInDate) {
    const checkIn = new Date(checkInDate);
    const today = new Date();
    const diferencia = Math.floor((today - checkIn) / (1000 * 3600 * 24)) + 1;
    return Math.max(diferencia, 0);
  }

  useEffect(() => {
    // Calcular la fecha de hace 14 días y la fecha actual
    const today = new Date();
    const fourteenDaysAgo = new Date(today);
    fourteenDaysAgo.setDate(today.getDate() - 14);

    // Convertir las fechas a objetos Timestamp de Firebase
    const startDate = Timestamp.fromDate(fourteenDaysAgo);
    const endDate = Timestamp.fromDate(today);

    // Referencia a la colección 'checkins' (ajusta el nombre de la colección)
    const checkinsRef = collection(db, "checkins");

    // Crear la consulta para obtener check-ins desde hace 14 días hasta hoy
    const q = query(
      checkinsRef,
      where("timestamp", ">=", startDate),
      where("timestamp", "<=", endDate),
      orderBy("timestamp", "desc")
    );

    const fetchData = async () => {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
  
      const startDate = Timestamp.fromDate(sevenDaysAgo);
      const endDate = Timestamp.fromDate(today);
  
      // Referencia a las colecciones
      const checkinsRef = collection(db, "checkins");
      const checkoutsRef = collection(db, "checkouts");
  
      // Consultar todos los checkouts
      const checkoutSnapshot = await getDocs(checkoutsRef);
      const checkoutSet = new Set();
  
      // Almacenar combinaciones únicas de 'nombreHuesped' y 'habitacion' de checkouts
      checkoutSnapshot.forEach((doc) => {
        const { nombreHuesped, habitacion } = doc.data();
        checkoutSet.add(`${nombreHuesped}-${habitacion}`);
      });
  
      // Consultar checkins de los últimos 7 días
      const q = query(
        checkinsRef,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate),
        orderBy("timestamp", "desc")
      );
      const checkinSnapshot = await getDocs(q);
  
      const guestsList = [];
      checkinSnapshot.forEach((doc) => {
        const guestData = doc.data();
        const { nombreHuesped, habitacion, timestamp, costo, adultos, ninos, tipoHabitacion } = guestData;
  
        // Si el huésped ya está en checkout, omitirlo
        if (checkoutSet.has(`${nombreHuesped}-${habitacion}`)) return;
  
        // Formatear datos
        const checkInDate = new Date(timestamp.seconds * 1000);
        const checkInDateFormatted = checkInDate.toLocaleString();
        const noches = calcularNochesCompletas(checkInDate);
        const totalCost = noches > 0 ? costo * noches : costo;
  
        guestsList.push({
          nombreHuesped,
          habitacion,
          tipoHabitacion,
          checkInDate: checkInDateFormatted,
          checkoutDate: "-",
          totalCost,
          adultos,
          ninos,
          documentId: doc.id,
        });
      });
  
      setGuests(guestsList);
    };
  
    fetchData();
  }, []);
  // Función para manejar la apertura del modal
  const handleOpenModal = (guest) => {
    setSelectedGuest(guest);
    setIsModalOpen(true);
  };

  // Función para manejar el cierre del modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPaymentMethod("");
  };

// Función para manejar el checkout
const handleCheckout = async () => {
  const confirmed = window.confirm("¿Estás seguro de que deseas realizar el checkout?");
  if (confirmed && selectedGuest) {
    const { documentId, nombreHuesped, habitacion, totalCost } = selectedGuest;
    const checkoutTimestamp = Timestamp.fromDate(new Date());

    // Crear el documento de checkout
    const checkoutRef = doc(collection(db, "checkouts"));
    await setDoc(checkoutRef, {
      nombreHuesped,
      habitacion,
      timestamp: checkoutTimestamp,
      totalCost,
      paymentMethod,
    });

    // Marcar como "checkedOut" en la colección "checkins"
    const checkinRef = doc(db, "checkins", documentId);
    await setDoc(checkinRef, { checkedOut: true }, { merge: true });

    // Cerrar el modal
    handleCloseModal();
    alert("Checkout realizado con éxito.");
  }
};

  return (
    <div className="PagePrinc_DB">
      {/* Tabla de huéspedes */}
      <div className="lista-huespedes">
        <div className="ecabezadoPrincipal">
          <h3>Lista de huéspedes recientes</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Check In</th>
              <th>Huespedes</th>
              <th>Habitación</th>
              <th>Precio/noche</th>
            </tr>
          </thead>
          <tbody>
            {guests.map((guest, index) => (
              <tr key={index} onClick={() => handleOpenModal(guest)}>
                <td>{guest.nombreHuesped}</td>
                <td className={`etiqueta ${guest.tipoHabitacion.toLowerCase()}`}>{guest.tipoHabitacion}</td>
                <td>{guest.checkInDate}</td>
                <td>A:{guest.adultos} ;N: {guest.ninos} </td>
                <td>{guest.habitacion}</td>
                <td>${guest.totalCost}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal de checkout */}
      {isModalOpen && selectedGuest && (
        <div className="modalPP">
          <div className="modalPP-content">
            <h3>Checkout para {selectedGuest.nombreHuesped}</h3>
            <p>Total: ${selectedGuest.totalCost}</p>
         

            <div className="modalPP-actions">
              <button onClick={handleCheckout}>Check Out</button>
              <button onClick={handleCloseModal}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrincipalDB;
