import "./accRap_dashboard_style.css";
import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc, addDoc } from 'firebase/firestore';
import { db } from "../firebase";
import emailjs from "emailjs-com";

function LateralDashboard() {
  const [habitacion, setHabitacion] = useState("");
  const [adultos, setAdultos] = useState(0);
  const [ninos, setNinos] = useState(0);
  const [nombreHuesped, setNombreHuesped] = useState("");
  const [costo, setCosto] = useState(0);
  const [precioTotal, setprecioTotal] = useState(0);
  const [tipoHabitacion, setTipoHabitacion] = useState("");
  const [habitacionesOcupadas, setHabitacionesOcupadas] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [phoneHuesped, setPhone] = useState("");
  const [noches, setNoches] = useState("");
  const [emailHuesped, setEmailHuesped] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(""); // Para almacenar el método de pago seleccionado

  const habitaciones = {
    "Habitacion 1": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 2": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 3": { tipo: "Doble Queen", precio: 1600 },
    "Habitacion 4": { tipo: "Familiar", precio: 1800 },
    "Habitacion 5": { tipo: "Familiar", precio: 1800 },
    "Habitacion 6": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 7": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 8": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 9": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 10": { tipo: "King", precio: 1500 },
    "Habitacion 11": { tipo: "King", precio: 1500 },
    "Habitacion 12": { tipo: "Doble Matrimonial", precio: 1600 },
    "Habitacion 13": { tipo: "King", precio: 1500 },
    "Habitacion 14": { tipo: "King", precio: 1500 },
    "Habitacion 15": { tipo: "King", precio: 1500 },
    "Habitacion 16": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 17": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 18": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 19": { tipo: "King", precio: 1500 },
    "Habitacion 20": { tipo: "Doble Matrimonial", precio: 1600 },
  };



  // Fetch habitaciones ocupadas desde Firestore
  const fetchHabitacionesOcupadas = async () => {
    try {
      const checkinSnapshot = await getDocs(collection(db, "checkins"));
      const ocupadas = [];

      // Habitaciones ocupadas en checkin
      checkinSnapshot.forEach((doc) => {
        ocupadas.push(doc.data().habitacion);
      });

      setHabitacionesOcupadas(ocupadas);
    } catch (error) {
      console.error("Error al obtener habitaciones ocupadas: ", error);
    }
  };

  useEffect(() => {
    fetchHabitacionesOcupadas();
  }, []);

  //

  const handleHabitacionChange = (e) => {
    const selectedHabitacion = e.target.value;
    setHabitacion(selectedHabitacion);

    if (habitaciones[selectedHabitacion]) {
      const { tipo, precio } = habitaciones[selectedHabitacion];
      setTipoHabitacion(tipo);
      setCosto(precio);
    } else {
      setTipoHabitacion("");
      setCosto(0);
    }
  };


  // Actualizar estados cuando cambian los inputs
  const handleAdultosChange = (e) => setAdultos(e.target.value);
  const handleNinosChange = (e) => setNinos(e.target.value);
  const handleNombreHuespedChange = (e) => setNombreHuesped(e.target.value);
  const handlephoneHuespedChange = (e) => setPhone(e.target.value);
  const handlenochesChange = (e) => setNoches(e.target.value);
  const handleemailChange = (e) => setEmailHuesped(e.target.value);

  // Calcular el precio total automáticamente
  useEffect(() => {
    const total = noches * costo;
    setprecioTotal(total);
  }, [noches, costo]);

  const handleSubmitCheckin = async () => {
    try {
      await addDoc(collection(db, "checkins"), {
        habitacion,
        noches,
        tipoHabitacion,
        ninos,
        adultos,
        nombreHuesped,
        costo,
        phoneHuesped,
        emailHuesped,
        precioTotal,
        paymentMethod,
        timestamp: new Date(),
      });
      alert("Check-in registrado correctamente");
      setShowConfirmModal(false);
    } catch (error) {
      console.error("Error al registrar check-in: ", error);
      alert("Hubo un problema al registrar el check-in");
    }
  };

  const handleSendEmail = () => {
    const serviceID = "service_x9zvjnf"; // Reemplaza con tu Service ID de EmailJS
    const templateID = "template_tl2o6fv"; // Reemplaza con tu Template ID de EmailJS
    const userID = "d5GDAbW6oY3i5s9ub"; // Reemplaza con tu User ID de EmailJS
  
    // Datos del formulario
    const formData = {
      nombreHuesped,
      emailHuesped,
      phoneHuesped,
      habitacion,
      tipoHabitacion,
      noches,
      adultos,
      ninos,
      precioTotal,
      paymentMethod,
    };
  
    // Enviar el correo con EmailJS
    emailjs
      .send(serviceID, templateID, formData, userID)
      .then(
        (response) => {
          console.log(
            "Correo enviado exitosamente",
            response.status,
            response.text
          );
          alert("Correo enviado con éxito");
        },
        (error) => {
          console.error("Hubo un error al enviar el correo", error);
          alert("Hubo un error al enviar el correo");
        }
      );
  };
  



  return (
    <aside className="accRapida_DB">
      <button className="botonCI_accRapida active">Formulario Check in</button>
      <div className="campo">
        <label htmlFor="habitacion">
          <p>
            <b>Habitación</b>
          </p>
        </label>
        <select
          id="habitacion"
          value={habitacion}
          onChange={handleHabitacionChange}
        >
          <option value="">Selecciona una habitación</option>
          {Object.keys(habitaciones)
            .map((hab) => (
              <option key={hab} value={hab}>
                {hab.split(" ")[1]} {/* Mostrar solo el número de habitación */}
              </option>
            ))}
        </select>
      </div>

      <div className="tipoHab_DB_cont">
        <p>
          <b>Tipo de habitación</b>
        </p>
        {tipoHabitacion && (
          <a className="tipoHabTag" id="tipoHabitacion">
            {tipoHabitacion}
          </a>
        )}
      </div>
      <div className="campo noches_form">
        <label>
          <p>
            <b>Noches</b>
          </p>
        </label>
        <input
          placeholder="Noches"
          type="number"
          id="noches"
          name="noches"
          value={noches}
          onChange={handlenochesChange}
          min="0"
          max="7
              ∫"
          step="1"
          required
        />
      </div>

      <div className="personas_AccRap">
        <p>
          <b>Personas</b>
        </p>

        <a>
          <label>Adultos</label>
          <input
            placeholder="Adultos"
            type="number"
            id="adultos"
            name="adultos"
            value={adultos}
            onChange={handleAdultosChange}
            min="0"
            max="8"
            step="1"
            required
          />
          <label>Niños</label>
          <input
            placeholder="Niños"
            type="number"
            id="ninos"
            name="ninos"
            value={ninos}
            onChange={handleNinosChange}
            min="0"
            max="8"
            step="1"
            required
          />
        </a>
      </div>

      <div className="nombreHue_DB_cont">
        <p>
          <b>Nombre del huesped</b>
        </p>
        <input
          type="text"
          placeholder="Jhon Doe"
          id="nombre"
          value={nombreHuesped}
          onChange={handleNombreHuespedChange}
          required
        ></input>
      </div>

      <div className="nombreHue_DB_cont">
        <p>
          <b>Teléfono</b>
        </p>
        <input
          type="number"
          placeholder="(722)1231234"
          id="phone"
          value={phoneHuesped}
          onChange={handlephoneHuespedChange}
          required
        ></input>
      </div>
      <div className="nombreHue_DB_cont">
        <p>
          <b>Correo</b>
        </p>
        <input
          type="text"
          placeholder="ejemplo@correo.com"
          id="email"
          value={emailHuesped}
          onChange={handleemailChange}
          required
        ></input>
      </div>
      <div className="formaPago_DB_cont">
        <p>
          <b>Forma de pago</b>
        </p>
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <option value="">Seleccionar</option>
          <option value="Efectivo">Efectivo</option>
          <option value="Tarjeta de crédito">Tarjeta de debito</option>
          <option value="Tarjeta de crédito">Tarjeta de crédito</option>
          <option value="Transferencia bancaria">Transferencia bancaria</option>
        </select>
      </div>
      <hr />
      <div className="campo-total">
        <hr />
        <a>
          <p>
            <b>Cargo por noche</b>
          </p>
          <p className="costo">
            <b>${costo}</b>
          </p>
        </a>
        <a>
          <p>
            <b>Cargo total</b>
          </p>
          <p className="costo">
            <b>${precioTotal}</b>
          </p>
        </a>
        <hr />
      </div>
      <button
        className="botonProceder"
        onClick={() => setShowConfirmModal(true)}
      >
        PROCEDER
      </button>
      {showConfirmModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirmación de Reserva</h3>
            <p>
              <b>Habitación:</b> {habitacion}
            </p>
            <p>
              <b>Noches:</b> {noches}
            </p>
            <p>
              <b>Tipo:</b> {tipoHabitacion}
            </p>
            <p>
              <b>Adultos:</b> {adultos}
            </p>
            <p>
              <b>Niños:</b> {ninos}
            </p>
            <p>
              <b>Nombre del Huésped:</b> {nombreHuesped}
            </p>
            <p>
              <b>Precio por noche:</b> ${costo}
            </p>
            <p>
              <b>Teléfono:</b> {phoneHuesped}
            </p>
            <p>
              <b>Correo:</b> {emailHuesped}
            </p>
            <p>
              <b>Forma de pago:</b> {paymentMethod}
            </p>
            <div className="contBotonesAR">
            <button className="botonEnviarCI" onClick={() => {handleSendEmail();}}>Enviar comprobantes</button>
            <button className="botonSubmintCI" onClick={handleSubmitCheckin}>Confirmar</button>
            <button  className="botonCancelarCI"onClick={() => setShowConfirmModal(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
}

export default LateralDashboard;
