import React, { useState } from "react";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import "./paginaHabitacion_style.css";

function Gallery({ images }) {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className="gallery-container">
      {/* Imagen grande */}
      <div className="main-image">
        <img src={selectedImage} alt="Selected" />
      </div>

      {/* Miniaturas */}
      <div className="thumbnail-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className={`thumbnail ${image === selectedImage ? "active" : ""}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
    </div>
  );
}

function PaginaHabitacionFam() {
  const images = [

    "../imagenes/Familiar/Copia de 462227730_1299055814582885_697985619645913819_n.jpg",
    "../imagenes/Familiar/IMG_8958.JPG",
    "../imagenes/Familiar/IMG_8959.JPG",
    "../imagenes/Familiar/IMG_8966.JPG",
  ];

  const [popupVisible, setPopupVisible] = useState(false);
  const [reservationStep, setReservationStep] = useState(1);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date_in: "",
    date_out: "",
    guests: 1,
  });

  // Manejo del formulario
  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePhone = (phone) => /^[0-9]{10}$/.test(phone);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, phone } = formData;

    if (!validateEmail(email)) {
      alert("Por favor, ingresa un correo electrónico válido.");
      return;
    }

    if (!validatePhone(phone)) {
      alert("Por favor, ingresa un número de teléfono válido (10 dígitos).");
      return;
    }

    setReservationStep(2);
  };

  const handlePaymentRedirect = () => {
    addDoc(collection(db, "reservations"), formData)
      .then(() => {
        window.open("https://buy.stripe.com/test_6oE17744n3S19Ow6os", "_blank");
        setReservationStep(3);
      })
      .catch((error) => {
        console.error("Error al guardar la reservación: ", error);
        alert("Ocurrió un error al guardar tu reservación.");
      });
  };

  const openPopup = (room) => {
    setCurrentRoom(room);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setReservationStep(1);
    setCurrentRoom(null);
  };

  return (
    <div className="contenidoPRoom">
      <div className="encabezado_PRoom">
        <h1>Habitación Familiar</h1>
      </div>
      <div>
        <section className="principalPRoom">
          <div className="galeriaRoom">
            <Gallery images={images} />
          </div>
          <div className="infoRoomHab">
            <div className="precioReservar">
              <h2>Familiar</h2>
              <hr />
              <a>
                <h3 className="precio">$1600</h3>
                <h4>/noche</h4>
              </a>
              <p>
                El precio de la habitación es por noche. En caso de hospedarse
                una persona adicional, se cobrará un suplemento de $100.
              </p>
              <button className="botonReservarRoom"
              onClick={() => openPopup()}
              >Reservar</button>
            </div>
            <div className="contComodidades">
              <a className="tagComodidades">
                <img
                  alt=""
                  className="iconoComodidades"
                  src="../imagenes/iconos/wifiblack.svg"
                ></img>
                WiFi
              </a>
              <a className="tagComodidades">
                <img
                  alt=""
                  className="iconoComodidades"
                  src="../imagenes/iconos/estacionamientioBlack.svg"
                ></img>
                Estacionamiento
              </a>
              <a className="tagComodidades">
                <img
                  alt=""
                  className="iconoComodidades"
                  src="../imagenes/iconos/aireblack.svg"
                ></img>
                Aire Acondicionado (algunas habitaciones)
              </a>
            </div>
          </div>
        </section>
        <hr className="separador1Room" />
        <section className="infoGral_Room">
          <h2>Más información</h2>
          <p>
            La opción ideal para grupos o familias numerosas, nuestra habitación
            Familiar combina espacio y comodidad con dos camas Queen Size y dos
            camas individuales. Diseñada para garantizar que cada miembro de tu
            grupo tenga su propio espacio, esta habitación es perfecta para
            crear recuerdos inolvidables mientras disfrutas de todas las
            comodidades que ofrecemos.{" "}
          </p>
        </section>
        <section className="linksRoom">
          <div className="AboutusRoom">
            <h3>Nuestro hotel</h3>
            <p>
              Ubicados en el corazón de Valle de Bravo, somos más que un hotel:
              somos un punto de encuentro para quienes buscan comodidad,
              hospitalidad y proximidad a las principales atracciones del
              destino.
            </p>
            <a href="/AboutUs" className="botonRoomsLinks">
              Conocer más
            </a>
          </div>
          <div className="AboutusRoom2">
            <h3>Contáctanos</h3>
            <p>
              Nos encantaría saber de ti. Ya sea para resolver tus dudas,
              ayudarte a planificar tu visita o recibir tus comentarios, estamos
              aquí para ti.
            </p>
            <a href="" className="botonRoomsLinks">
              Enviar mensaje
            </a>
          </div>
        </section>
      </div>
      {/* Popup */}
      {popupVisible && (
        <div className="popupOverlay">
          <div className="popupContent">
            {reservationStep === 1 && (
              <>
                <div className="cont_encabezadoReservaciones">
                  <h2>RESERVACIONES</h2>
                  <img
                    className="logoFormulario"
                    src="../imagenes/logosanagustin_verde.svg"
                    alt="Reservaciones"
                  />
                </div>
                <div className="separadorFormCheckin">
                  <div className="contImg_FormCheckin">
                    <div className="pasoCheckin">
                      <p>1/3</p>
                    </div>
                    <img
                      className="imagenCheckin"
                      src="../imagenes/recepcionista.svg"
                    />
                  </div>
                  <form className="formularioCheckin" onSubmit={handleSubmit}>
                    <div className="fechasCheckin">
                      <a>
                        <label htmlFor="dates">Llegada:</label>
                        <input
                          type="date"
                          id="date_in"
                          name="date_in"
                          value={formData.date_in}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                      <a>
                        <label htmlFor="dates">Salida:</label>
                        <input
                          type="date"
                          id="date_out"
                          name="date_out"
                          value={formData.date_out}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                    </div>
                    <div className="infohuespedes_Checkin">
                      <a>
                        <label htmlFor="NumerodeHuespedes">Huespedes:</label>
                        <input
                          type="number"
                          id="hosts"
                          name="guests"
                          value={formData.guests}
                          onChange={handleFormChange}
                          required
                          min="1"
                          max="7"
                        />
                      </a>
                      <a>
                        <label htmlFor="name">Nombre:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                    </div>

                    <div className="contacto_Checkin">
                      <a>
                        <label htmlFor="email">Correo Electrónico:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                      <a>
                        <label htmlFor="phone">Teléfono:</label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleFormChange}
                          required
                        />
                      </a>
                    </div>

                    <button type="submit">siguiente</button>
                  </form>
                  <button className="closePopup" onClick={closePopup}>
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </>
            )}
            {reservationStep === 2 && (
              <>
                <div className="cont_encabezadoReservaciones">
                  <h2>RESERVACIONES</h2>
                  <img
                    className="logoFormulario"
                    src="../imagenes/logosanagustin_verde.svg"
                    alt="Reservaciones"
                  />
                </div>
                <div className="separadorFormCheckin">
                  <div className="contImg_FormGracias">
                    <div className="pasoCheckin paso_Gracias">
                      <p>2/3</p>
                    </div>
                    <img
                      className="imagenGracias"
                      src="../imagenes/modelotelefono.svg"
                    />
                  </div>
                  <div className="texto_GraciasReserva">
                    <h1 className="texto_h2gracias">Confirmación</h1>
                    <p>
                      Para completar tu reservación, es necesario realizar un
                      adelanto del 50% del costo de una noche.
                    </p>
                    <p className="texto_avisoPopUp">
                      No te preocupes, si llegas a cancelar o no puedes asistir,
                      el monto se mantendrá acreditado para una futura
                      reservación.
                    </p>
                    <button
                     onClick={handlePaymentRedirect}
                    >
                      Confirmar y pagar
                    </button>
                  </div>
                  <button className="closePopup" onClick={closePopup}>
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </>
            )}
            {reservationStep === 3 && (
              <>
                <div className="cont_encabezadoReservaciones">
                  <h2>RESERVACIONES</h2>
                  <img
                    className="logoFormulario"
                    src="../imagenes/logosanagustin_verde.svg"
                    alt="Reservaciones"
                  />
                </div>
                <div className="separadorFormCheckin">
                  <div className="contImg_FormGracias">
                    <div className="pasoCheckin paso_Gracias">
                      <p>3/3</p>
                    </div>
                  </div>
                  <div className="texto_GraciasReserva">
                    <h1 className="texto_h2gracias">
                      Tu reservación está casi lista
                    </h1>
                    <p>
                      Es todo en esta ventana continua procediendo con tu pago a
                      traves de stripe con el fin de hacerlo
                    </p>
                    <p className="texto_avisoPopUp">
                      No te preocupes, si llegas a cancelar o no puedes asistir,
                      el monto se mantendrá acreditado para una futura
                      reservación.
                    </p>
                  </div>
                  <button className="closePopup" onClick={closePopup}>
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PaginaHabitacionFam;
