import { useState } from "react";
import "../Barra_de_navegacion/navbar.css";

function Navbar() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <header className="barra_nav">
      <a className="logo_cont" href="/">
        <img
          alt="Hotel Real San Agustín"
          className="logoNavbar"
          src="./imagenes/logosanagustinBLANCO.png"
        />
      </a>
      <div className={`secc_cont ${menuVisible ? "active" : ""}`}>
        <a className="boton_seccion" href="/Habitaciones">HABITACIONES</a>
        <a className="boton_seccion" href="/Blog">BLOG</a>
        <a className="boton_seccion" href="/GaleriaPrincipal">GALERÍA</a>
        <a className="boton_seccion" href="/AboutUs">NOSOTROS</a>
      </div>
      <div className="contacto_cont oculto">
        <a className="boton_contacto" href="/AboutUs#contacto">CONTACTO</a>
      </div>
      <div className="burger_menu" onClick={toggleMenu}>
        <div className="burger_line"></div>
        <div className="burger_line"></div>
        <div className="burger_line"></div>
      </div>
    </header>
  );
}

export default Navbar;
