import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import "./principalDB_style.css";

function Reservaciones() {
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedReservacion, setSelectedReservacion] = useState(null);
  const [habitacion, setHabitacion] = useState("");
  const [adultos, setAdultos] = useState(0);
  const [ninos, setNinos] = useState(0);
  const [nombreHuesped, setNombreHuesped] = useState("");
  const [costo, setCosto] = useState(1500);
  const [tipoHabitacion, setTipoHabitacion] = useState("");
  const [habitacionesOcupadas, setHabitacionesOcupadas] = useState([]);
  const [reservaciones, setReservaciones] = useState([]);
  const [fecha, setFecha] = useState("");

  const habitaciones = {
    "Habitacion 1": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 2": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 3": { tipo: "Doble Queen", precio: 1600 },
    "Habitacion 4": { tipo: "Familiar", precio: 1800 },
    "Habitacion 5": { tipo: "Familiar", precio: 1800 },
    "Habitacion 6": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 7": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 8": { tipo: "Doble Queen", precio: 1600 },
    "Habitacion 9": { tipo: "Familiar", precio: 1800 },
    "Habitacion 10": { tipo: "Familiar", precio: 1800 },
    "Habitacion 11": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 13": { tipo: "Doble Queen", precio: 1600 },
    "Habitacion 14": { tipo: "Familiar", precio: 1800 },
    "Habitacion 15": { tipo: "Familiar", precio: 1800 },
    "Habitacion 16": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 17": { tipo: "Queen Standard", precio: 1500 },
    "Habitacion 18": { tipo: "Doble Queen", precio: 1600 },
    "Habitacion 19": { tipo: "Familiar", precio: 1800 },
    "Habitacion 20": { tipo: "Familiar", precio: 1800 },
  };

  useEffect(() => {
    const fetchReservaciones = async () => {
      try {
        const reservacionesSnapshot = await getDocs(
          collection(db, "reservaciones")
        );
        const reservacionesData = reservacionesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Filtrar reservaciones con fechas futuras o actuales
        const fechaActual = new Date().toISOString().split("T")[0];
        const reservacionesValidas = reservacionesData
          .filter((reserva) => reserva.fecha >= fechaActual)
          .sort((a, b) => new Date(a.fecha) - new Date(b.fecha)); // Ordenar por fecha ascendente
  
        setReservaciones(reservacionesValidas);
      } catch (error) {
        console.error("Error al obtener reservaciones: ", error);
      }
    };
  
    fetchReservaciones();
  }, []);
  
  

  const handleHabitacionChange = (e) => {
    const selectedHabitacion = e.target.value;
    setHabitacion(selectedHabitacion);

    if (habitaciones[selectedHabitacion]) {
      const { tipo, precio } = habitaciones[selectedHabitacion];
      setTipoHabitacion(tipo);
      setCosto(precio);
    } else {
      setTipoHabitacion("");
      setCosto(0);
    }
  };

  const handleSubmitReservacion = async (e) => {
    e.preventDefault();
  
    if (!habitacion || !nombreHuesped || !fecha) {
      alert("Por favor, completa todos los campos obligatorios.");
      return;
    }
  
    const nuevaReservacion = {
      habitacion,
      tipoHabitacion,
      adultos,
      ninos,
      nombreHuesped,
      costo,
      fecha,
      timestamp: new Date().toLocaleString(),
    };
  
    try {
      await addDoc(collection(db, "reservaciones"), nuevaReservacion);
      const reservacionesActualizadas = [...reservaciones, nuevaReservacion];
  
      // Ordenar antes de actualizar el estado
      reservacionesActualizadas.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
      setReservaciones(reservacionesActualizadas);
  
      setShowPopup(false);
      setHabitacion("");
      setAdultos(0);
      setNinos(0);
      setNombreHuesped("");
      setCosto(1500);
      setTipoHabitacion("");
      setFecha("");
    } catch (error) {
      console.error("Error al guardar la reservación: ", error);
    }
  };
  

  const handleDeleteReservacion = async (reason) => {
    try {
      await deleteDoc(doc(db, "reservaciones", selectedReservacion.id));
      setReservaciones(
        reservaciones.filter((r) => r.id !== selectedReservacion.id)
      );
      setHabitacionesOcupadas([
        ...habitacionesOcupadas,
        selectedReservacion.habitacion,
      ]);
      alert(`Reservación eliminada por: ${reason}`);
      setShowDeletePopup(false);
      setSelectedReservacion(null);
    } catch (error) {
      console.error("Error al eliminar la reservación: ", error);
    }
  };

  return (
    <div className="reservaciones-page">
      <div className="header">
        <h3>Reservaciones</h3>
        <button className="btn-nuevo" onClick={() => setShowPopup(true)}>
          Nuevo registro
        </button>
      </div>

      {showPopup && (
        <div className="popupRes-overlay">
          <div className="popupRes-box">
            <h3>Registrar Nueva Reservación</h3>
            <form onSubmit={handleSubmitReservacion}>
              <label>Habitación:</label>
              <select
                value={habitacion}
                onChange={handleHabitacionChange}
                required
              >
                <option value="">Selecciona una habitación</option>
                {Object.keys(habitaciones)
                  .filter((hab) => !habitacionesOcupadas.includes(hab))
                  .map((hab) => (
                    <option key={hab} value={hab}>
                      {hab.split(" ")[1]} ({habitaciones[hab].tipo})
                    </option>
                  ))}
              </select>

              <label>Nombre del huésped:</label>
              <input
                type="text"
                value={nombreHuesped}
                onChange={(e) => setNombreHuesped(e.target.value)}
                required
              />

              <label>Fecha de la reservación:</label>
              <input
                type="date"
                value={fecha}
                onChange={(e) => setFecha(e.target.value)}
                required
              />

              <div className="adNin_NuevaRes">
                <a>
                  <label>Adultos:</label>
                  <input
                    type="number"
                    value={adultos}
                    onChange={(e) => setAdultos(Number(e.target.value))}
                    min="0"
                    max="8"
                    required
                  />
                </a>
                <a>
                  <label>Niños:</label>
                  <input
                    type="number"
                    value={ninos}
                    onChange={(e) => setNinos(Number(e.target.value))}
                    min="0"
                    max="8"
                  />
                </a>
              </div>

              <label>Costo:</label>
              <input className="input_precioReservacion" type="number" value={costo} readOnly />
<div className="botones_NuevaReservacion">
                <button type="submit" className="boton_VerdeNR"> <b className="simboloMas">+</b> Agregar</button>
              <button type="button" onClick={() => setShowPopup(false)}>
                Cerrar
              </button>
</div>

            </form>
          </div>
        </div>
      )}

      {showDeletePopup && (
        <div className="popupRes-overlay">
          <div className="popupRes-box">
            <h3>Motivo de eliminación</h3>
            <button className="botonCancelacionRes" onClick={() => handleDeleteReservacion("Cancelación")}>
              Cancelación
            </button>
            <button
            className="boton_VerdeNR"
              onClick={() => handleDeleteReservacion("Check-in realizado")}
            >
              Check-in realizado
            </button>
            <button onClick={() => setShowDeletePopup(false)}>Cerrar</button>
          </div>
        </div>
      )}

      <div className="tabla-reservaciones">
        <h3>Reservaciones Pendientes</h3>
        <table>
          <thead>
            <tr>
              <th>Habitación</th>
              <th>Tipo</th>
              <th>Huésped</th>
              <th>Adultos</th>
              <th>Niños</th>
              <th>Costo</th>
              <th>Fecha</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reservaciones.map((reserva, index) => (
              <tr key={index}>
                <td>{reserva.habitacion}</td>
                <td>{reserva.tipoHabitacion}</td>
                <td>{reserva.nombreHuesped}</td>
                <td>{reserva.adultos}</td>
                <td>{reserva.ninos}</td>
                <td>${reserva.costo}</td>
                <td>{reserva.fecha}</td>
                <td>
                  <a
                    className="botonER eliminarReservacion"
                    onClick={() => {
                      setSelectedReservacion(reserva);
                      setShowDeletePopup(true);
                    }}
                  >
                      Eliminar               
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Reservaciones;
