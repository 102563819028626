// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, orderBy, doc, setDoc, deleteDoc } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7BDcat09eiSe1Hy1FhIMjxiz3jz0WfHs",
  authDomain: "sanagustin-bfb7c.firebaseapp.com",
  projectId: "sanagustin-bfb7c",
  storageBucket: "sanagustin-bfb7c.firebasestorage.app",
  messagingSenderId: "893575424682",
  appId: "1:893575424682:web:0fbebe9faadd9c0b72fed3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, getDocs, query, where, orderBy, doc, setDoc, deleteDoc };
