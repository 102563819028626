import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMotorcycle,
  faCar,
  faTruckPickup,
} from "@fortawesome/free-solid-svg-icons";
import "./parkingSystem_style.css";
import { db } from "../firebase"; // Importa la configuración de Firestore
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";

const ParkingSystem = () => {
  const [vehicleType, setVehicleType] = useState(null);
  const [formData, setFormData] = useState({
    marca: "",
    modelo: "",
    placa: "",
    telefono: "",
    lugar: "",
    habitacion: "",
  });
  const [parkingSlots, setParkingSlots] = useState(
    Array(27).fill({ ocupado: false, datos: null })
  );
  const [selectedSlot, setSelectedSlot] = useState(null);

  // Sincronización inicial con Firestore
  useEffect(() => {
    const fetchParkingSlots = async () => {
      try {
        const snapshot = await getDocs(collection(db, "estacionamiento"));
        const slotsData = Array(27).fill({ ocupado: false, datos: null });

        snapshot.forEach((doc) => {
          const data = doc.data();
          const index = data.lugar - 1; // Convertir lugar a índice
          slotsData[index] = { ocupado: true, datos: { ...data, id: doc.id } };
        });

        setParkingSlots(slotsData);
      } catch (error) {
        console.error("Error al obtener los datos de Firestore:", error);
      }
    };

    fetchParkingSlots();
  }, []);

  const handleVehicleTypeChange = (type) => {
    setVehicleType(type);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async () => {
    const index = Number(formData.lugar) - 1; // Convertir lugar a índice

    if (index < 0 || index >= parkingSlots.length) {
      alert("Lugar inválido.");
      return;
    }

    if (parkingSlots[index].ocupado) {
      alert("El lugar ya está ocupado.");
      return;
    }

    try {
      // Enviar datos a Firestore
      const docRef = await addDoc(collection(db, "estacionamiento"), {
        ...formData,
        tipoVehiculo: vehicleType,
        fechaHora: serverTimestamp(),
        lugar: Number(formData.lugar),
      });

      // Actualizar los espacios de estacionamiento en el estado local
      const newSlots = [...parkingSlots];
      newSlots[index] = { ocupado: true, datos: { ...formData, id: docRef.id } };
      setParkingSlots(newSlots);

      // Resetear el formulario
      setFormData({
        marca: "",
        modelo: "",
        placa: "",
        telefono: "",
        lugar: "",
        habitacion: "",
      });

      alert("Vehículo registrado con éxito.");
    } catch (error) {
      console.error("Error al registrar en Firestore:", error);
      alert("Hubo un error al registrar el vehículo.");
    }
  };

  const handleSlotClick = (index) => {
    if (parkingSlots[index].ocupado) {
      setSelectedSlot(index);
    } else {
      alert("El lugar no está ocupado.");
    }
  };

  const handleVehicleExit = async (type) => {
    const slotData = parkingSlots[selectedSlot]?.datos;

    if (!slotData) {
      alert("No hay datos para este lugar.");
      return;
    }

    try {
      if (type === "temporal") {
        await addDoc(collection(db, "salidasTemporales"), slotData);
      } else if (type === "final") {
        await addDoc(collection(db, "salidasFinales"), slotData);

        // Eliminar el registro correspondiente en Firestore
        await deleteDoc(doc(db, "estacionamiento", slotData.id));

        // Actualizar el estado local para liberar el espacio
        const newSlots = [...parkingSlots];
        newSlots[selectedSlot] = { ocupado: false, datos: null };
        setParkingSlots(newSlots);
      }

      alert(`Salida ${type === "temporal" ? "temporal" : "final"} registrada.`);
    } catch (error) {
      console.error("Error al registrar salida:", error);
      alert("Hubo un error al registrar la salida.");
    }

    setSelectedSlot(null);
  };

  return (
    <div className="parking-system">
      <div className="cont_ParkSys">
        <div className="datosVehiculo">
          {/* Selección de tipo de vehículo */}
          <div className="vehicle-type">
            {[
              { type: "Moto", icon: faMotorcycle },
              { type: "Auto", icon: faCar },
              { type: "Camioneta", icon: faTruckPickup },
            ].map(({ type, icon }) => (
              <button
                key={type}
                onClick={() => handleVehicleTypeChange(type)}
                className={vehicleType === type ? "active" : ""}
              >
                <FontAwesomeIcon icon={icon} style={{ marginRight: "8px" }} />
                {type}
              </button>
            ))}
          </div>

          {/* Formulario de datos del vehículo */}
          <div className="vehicle-form">
            <h2>Datos del vehículo</h2>
            <input
              list="marcas"
              name="marca"
              value={formData.marca}
              onChange={handleInputChange}
              placeholder="Marca"
            />
            <datalist id="marcas">
              <option value="Nissan" />
              <option value="General Motors" />
              <option value="Volkswagen" />
              <option value="Toyota" />
              <option value="KIA" />
              <option value="Mazda" />
              <option value="Stellantis" />
              <option value="Ford" />
              <option value="Hyundai" />
              <option value="MG Motor" />
              <option value="Honda" />
              <option value="Suzuki" />
              <option value="BMW" />
              <option value="Mercedes-Benz" />
              <option value="Audi" />
              <option value="Chevrolet" />
              <option value="Renault" />
              <option value="Mitsubishi" />
              <option value="Subaru" />
              <option value="Peugeot" />
            </datalist>

            <input
              name="modelo"
              value={formData.modelo}
              onChange={handleInputChange}
              placeholder="Modelo"
            />
            <input
              name="placa"
              value={formData.placa}
              onChange={handleInputChange}
              placeholder="Placa"
            />
            <input
              name="telefono"
              value={formData.telefono}
              onChange={handleInputChange}
              placeholder="Teléfono"
            />
            <input
              name="lugar"
              value={formData.lugar}
              onChange={handleInputChange}
              placeholder="Lugar"
              type="number"
            />
            <input
              name="habitacion"
              value={formData.habitacion}
              onChange={handleInputChange}
              placeholder="Habitación"
              list="habitacionesE"
            />
            <datalist id="habitacionesE">
              <option value="1" />
              <option value="2" />
              <option value="3" />
              <option value="4" />
              <option value="5" />
              <option value="6" />
              <option value="7" />
              <option value="8" />
              <option value="9" />
              <option value="10" />
              <option value="11" />
              <option value="13" />
              <option value="14" />
              <option value="15" />
              <option value="16" />
              <option value="17" />
              <option value="18" />
              <option value="19" />
              <option value="20" />
            </datalist>
            <button onClick={handleRegister}>Registrar</button>
          </div>
        </div>

        {/* Resumen */}
        <div className="summary">
          <h2>Resumen</h2>
          {vehicleType && <p>Tipo de vehículo: {vehicleType}</p>}
          {Object.entries(formData).map(([key, value]) => (
            <p key={key}>{`${
              key[0].toUpperCase() + key.slice(1)
            }: ${value}`}</p>
          ))}
        </div>

        {/* Distribución de estacionamiento */}
        <div className="parking-distribution">
          <h2>Distribución</h2>
          <div className="espaciosEstacionamiento">
            {parkingSlots.map((slot, index) => (
              <div
                key={index}
                className={`slot ${slot.ocupado ? "occupied" : "free"}`}
                onClick={() => handleSlotClick(index)}
              >
                <p>{`E${index+1}`}</p>
                {slot.ocupado && <p>{slot.datos.modelo}</p>}
              </div>
            ))}
          </div>
        </div>

        {/* Modal */}
        {selectedSlot !== null && (
          <div className="modal">
            <h2>Detalles del vehículo</h2>
            <p>Marca: {parkingSlots[selectedSlot].datos.marca}</p>
            <p>Modelo: {parkingSlots[selectedSlot].datos.modelo}</p>
            <p>Placa: {parkingSlots[selectedSlot].datos.placa}</p>
            <p>Teléfono: {parkingSlots[selectedSlot].datos.telefono}</p>
            <p>Habitación: {parkingSlots[selectedSlot].datos.habitacion}</p>
            <h2>Acciones para el Lugar {selectedSlot + 1}</h2>
          <button onClick={() => handleVehicleExit("temporal")}>Salida Temporal</button>
          <button onClick={() => handleVehicleExit("final")}>Salida Final</button>
            <button onClick={() => setSelectedSlot(null)}>Cerrar</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParkingSystem;
