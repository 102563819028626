import "./home_style.css";
import Buscar_Habitaciones from "../componentes/Home/buscador_habitaciones";
import PromocionMarina from "../componentes/Home/promocionMarina";
import GaleriaInicio from "../componentes/Home/galeriaHome";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="home">
      <div className="home_bannerprinc">
        <h1>BIENVENIDO A VALLE DE BRAVO</h1>
        <h2>EL DESCANSO TE ESPERA EN HOTEL REAL SAN AGUSTÍN</h2>
        <Link to="/Habitaciones">
        <button className="boton_reservar">RESERVAR</button>
        </Link> 
      </div>
      <div className="secc2_home">
        <img
          className="separador"
          src="./imagenes/separador_hornamental.svg"
        ></img>
        <h1>CONOCE NUESTRAS OPCIONES</h1>
        <h2>Contamos con opciones para todo tipo de visitantes </h2>
        <div className="contennedor_HabitacionesMuestra">
          <div className="habitación_Muestra">
            <img
              className="imagen_habMues"
              src="./imagenes/inicio /469703372_1348470862974713_3374909509485950929_n.jpg"
              alt="Habitación 2 personas"
            ></img>
            <div className="info_habitacionMenu">
            <h3>Habitación King</h3>
            <h4>2 personas</h4>
            <h4>$1,500</h4>
            <div className="botones_habMues">
              <button className="botonReservar_habMues">
                MÁS
              </button>
              <button className="botonReservar_habMues">RESERVAR</button>
            </div>
            </div>
           
          </div>
          <div className="habitación_Muestra">
            <img
              className="imagen_habMues"
              src="./imagenes/inicio /462149205_1299055804582886_1006545949177207373_n.jpg"
              alt="Habitación 2 personas"
            ></img>
            <div className="info_habitacionMenu">
              <h3>Habitación Doble Queen</h3>
            <h4>4 personas</h4>
            <h4>$1,600</h4>
            <div className="botones_habMues">
              <button className="botonReservar_habMues">
                MÁS
              </button>
              <button className="botonReservar_habMues">RESERVAR</button>
            </div>
            </div>
            
          </div>
          <div className="habitación_Muestra">
            <img
              className="imagen_habMues"
              src="./imagenes/inicio /462227730_1299055814582885_697985619645913819_n.jpg"
              alt="Habitación 2 personas"
            ></img>
            <div className="info_habitacionMenu">
            <h3>Habitación familiar</h3>
            <h4>6 personas</h4>
            <h4>$1,800</h4>
            <div className="botones_habMues">
              <button className="botonReservar_habMues">
               MÁS
              </button>
              <button className="botonReservar_habMues">RESERVAR</button>
            </div>  
            </div>
            
          </div>
        </div>
      </div>
      <hr className="separadorInferior_habMues" />
      <div className="contenedor_AU">
        <img src="./imagenes/greatvalue.svg" className="imagen_contAU"></img>
        <div className="texto_AUhome">
          <h3 className="letras_doradas">El hotel más centrico</h3>
          <h1>Todo valle de Bravo a unos pasos de distancia</h1>
          <p>
            Descubre la comodidad de hospedarte en el corazón de Valle de Bravo,
            donde cada rincón del pueblo mágico está a tu alcance. Desde
            pintorescas calles empedradas hasta restaurantes y atracciones
            turísticas, todo comienza aquí. 
          </p>
          <Link to="/AboutUs">
          <button className="botonAu_inicio">Ver más</button>
          </Link>
          
        </div>
      </div>
      <PromocionMarina />
      <GaleriaInicio />
    </div>
  );
}

export default Home;
