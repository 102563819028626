import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { users } from "../credenciales";
import { Link } from "react-router-dom";

import "./login_style.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    const user = users.find(
      (user) => user.username === username && user.password === password
    );

    if (user) {
      navigate("/dashboard"); // Redirige al área protegida
    } else {
      setErrorMessage("Credenciales incorrectas.");
    }
  };

  return (
    <div className="pageLogin">
      <div className="headerLogin">
        <Link to="/">
          <button className="botonRegresarLogin">
            <img
              className="flechaReverso"
              src="./imagenes/iconos/flecha1.svg"
            ></img>
            Regresar
          </button>
        </Link>
        <img
          className="logoLogin"
          alt="Hotel Real San Agustín"
          src="./imagenes/logosanagustin_verde.svg"
        ></img>
      </div>
      <div className="formularioCont">
        <div className="contImgLogin">
          <img className="imgLogin" src="./imagenes/imgLogin.svg" ></img>
          <h5>Sistema de <br/>Recepción</h5>
        </div>
        <div className="formulario">
          <h2>Bienvenido</h2>
          <p>Ingresa tu información</p>
          <input
          className="inputLogin"
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
          className="inputLogin"
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="recuperar_cuenta">Recuperar cuenta</button>
          <button onClick={handleLogin} className="Loginbutton">Iniciar sesión</button>
          {errorMessage && <p className="mensajeError_texto">Ingresa un usuario y contraseña validos.</p>}
        </div>
      </div>

      <div className="creditos">
        <h5>
          Desarrollado <br /> por
        </h5>
        <img
          className="iconofyf"
          src="./imagenes/iconos/formayfondo.svg"
          alt="Forma y Fondo Publicidad"
        ></img>
      </div>
    </div>
  );
}

export default Login;
