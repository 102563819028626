import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './componentes/Barra_de_navegacion/navbar.js';
import PieDepagina from "./componentes/Pie_de_pagina/footer.js";
import Home from './Paginas/home.js';
import AboutUS from './Paginas/AbouUs.js';
import Login from './Paginas/login.js';
import Dashboard from './Paginas/Dashboard.js';
import GaleriaPrincipal from "./Paginas/galeria.js";
import ScrollToTop from "./componentes/scrollToTop.js";

import Rooms from './Paginas/rooms.js';
import PaginaHabitacionQS from "./componentes/Habitaciones/habitacionQueenStandard.jsx";
import PaginaHabitacionKing from "./componentes/Habitaciones/habitacionKing.jsx";
import PaginaHabitacionDQ from "./componentes/Habitaciones/habitacionDobleQueen.jsx";
import PaginaHabitacionFam from "./componentes/Habitaciones/habitacionFamiliar.jsx";
import PaginaHabitacionDM from "./componentes/Habitaciones/habitacionDobleMatrimonial.jsx";

import Blog from './Paginas/blog.js';
import Articulo1Blog from './componentes/Blog/articuloBlog1.jsx';
import Articulo2Blog from "./componentes/Blog/articuloBlog2.jsx";
import Articulo3Blog from "./componentes/Blog/articuloBlog3.jsx";
import Articulo4Blog from "./componentes/Blog/articuloBlog4.jsx";
import Articulo5Blog from "./componentes/Blog/articuloBlog5.jsx";

import './App.css';

const App = () => {
  const location = useLocation();

  // Condición para ocultar Navbar y PieDepagina en rutas específicas
  const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/dashboard';

  return (
    <>
      {!hideHeaderFooter && <Navbar />}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUS />} />
        <Route path="/GaleriaPrincipal" element={<GaleriaPrincipal />} />

        <Route path="/Habitaciones">
          <Route index element={<Rooms />} />
          <Route path="HabitacionQueenStandard" element={<PaginaHabitacionQS />} />
          <Route path="HabitacionKing" element={<PaginaHabitacionKing />} />
          <Route path="HabitacionDobleQueen" element={<PaginaHabitacionDQ />} />
          <Route path="HabitacionFamiliar" element={<PaginaHabitacionFam />} />
          <Route path="HabitacionDobleMatrimonial" element={<PaginaHabitacionDM />} />
        </Route>

        <Route path="/Blog">
          <Route index element={<Blog />} />
          <Route path="Articulo1" element={<Articulo1Blog />} />
          <Route path="Articulo2" element={<Articulo2Blog />} />
          <Route path="Articulo3" element={<Articulo3Blog />} />
          <Route path="Articulo4" element={<Articulo4Blog />} />
          <Route path="Articulo5" element={<Articulo5Blog />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      {!hideHeaderFooter && <PieDepagina />}
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
