import "./articuloBlog_style.css";

function Articulo3Blog() {
  return (
    <div className="cont_ArticuloBlog">
      <div className="barra_paginaBlog">
        <h1>Blog</h1>
        <p>
          Valle de Bravo no solo es conocido por su naturaleza y belleza
          paisajística, sino también por su rica herencia cultural que se
          refleja en cada rincón del pueblo. Si eres un amante de la historia,
          el arte y las tradiciones locales, Valle de Bravo ofrece un recorrido
          fascinante por sus principales puntos culturales.
        </p>
      </div>
      <div className="encabezado_articulo encArt3">
        <h1>
          Visita Cultural en Valle de Bravo: Arte, Historia y Tradiciones
          Locales
        </h1>
        <p>
          Desde impresionantes iglesias coloniales hasta festivales llenos de
          color y música, aquí te dejamos una guía de los lugares y eventos que
          no te puedes perder.
        </p>
      </div>
      <hr />
      <div className="seccion1Articulo">
        <div className="texto1Articulo">
          <h3>1. La Parroquia de San Francisco de Asís</h3>
          <p>
            Uno de los principales íconos de la ciudad, la{" "}
            <b>Parroquia de San Francisco de Asís</b> es una joya arquitectónica
            que data del siglo XVII. Esta iglesia, ubicada en el centro de Valle
            de Bravo, es un claro ejemplo del estilo colonial que caracteriza a
            muchas construcciones del pueblo. Su fachada de piedra y el interior
            con detalles en madera y frescos antiguos cuentan la historia de la
            devoción religiosa de la comunidad. Además, la Parroquia se
            encuentra en una plaza rodeada de cafés y tiendas, lo que la
            convierte en un lugar perfecto para pasear y disfrutar del ambiente
            tranquilo del pueblo.
          </p>
          <h3>2. Museo de Arte Popular de Valle de Bravo</h3>
          <p>
            Para los amantes del arte, el <b>Museo de Arte Popular</b> de Valle
            de Bravo es una parada imperdible. Ubicado en una antigua casona del
            siglo XVIII, este museo alberga una rica colección de arte
            contemporáneo y tradicional, con obras de artistas tanto locales
            como internacionales. Las exposiciones rotativas y eventos
            especiales permiten una experiencia dinámica y siempre cambiante, lo
            que hace de este lugar un referente cultural en la región.
          </p>
          <ul>
            <li>
              <b>Tip:</b>
              Si tienes la oportunidad, asiste a las charlas o talleres que
              organiza el museo, donde podrás conocer más sobre el arte y la
              historia de Valle de Bravo.
            </li>
          </ul>
        </div>
        <div className="imagenArticulo_cont">
          <img
            src="https://www.mexicodesconocido.com.mx/assets/images/destinos/valle_de_bravo/actividades/valle-de-bravo-edomex-parroquia-de-san-francisco-de-asis.jpg"
            className="imagenArticulo"
            alt="Parroquia de San Francísco de Asís en el centro de Valle."
          ></img>
          <p className="piedeimagenArticulo">
            Parroquia de San Francísco de Asís en el centro de Valle.
          </p>
        </div>
      </div>
      <div className="seccion2Articulo">
        <div className="imagenArticulo_cont iac2">
          <img
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/mercado%20de%20artesanias%204.jpg"
            className="imagenArticulo"
            alt="Mercado de Artesanías, Valle de Bravo."
          ></img>
          <p className="piedeimagenArticulo">
            Mercado de Artesanías, Valle de Bravo.
          </p>
        </div>
        <div className="texto1Articulo">
          <h3>3. La Casa de la Cultura</h3>
          <p>
            Otro importante punto cultural en Valle de Bravo es la Casa de la
            Cultura, un espacio dedicado a promover las artes y tradiciones del
            pueblo. En su interior, se realizan exposiciones de pintura,
            escultura y fotografía, además de eventos relacionados con la danza,
            el teatro y la música. La Casa de la Cultura también ofrece clases y
            talleres para quienes deseen aprender más sobre las tradiciones
            artísticas de la región.
          </p>
          <ul>
            <li>
              <b>Tip:</b>
              Durante el verano, la Casa de la Cultura organiza festivales y
              presentaciones que reúnen a artistas locales y visitantes, creando
              un ambiente único de intercambio cultural.
            </li>
          </ul>
          <h3>4. El Mercado de Artesanías</h3>
          <p>
            El Mercado de Artesanías de Valle de Bravo es el lugar ideal para
            llevarse un pedazo de la tradición local. Aquí encontrarás una
            amplia variedad de productos hechos a mano por artesanos de la
            región, como textiles, cerámica, joyería y artículos de madera. Este
            mercado no solo es una excelente oportunidad para comprar souvenirs,
            sino también para conocer las técnicas tradicionales que se han
            transmitido de generación en generación.
          </p>
          <ul>
            <li>
              <p><b>Tip:</b>Visita el mercado los fines de semana, cuando hay más
              puestos y una variedad más amplia de productos.</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="texto_adicional">
      <p>Valle de Bravo es un destino que va más allá de su belleza natural, ofreciendo una gran riqueza cultural que refleja la historia y las tradiciones de la región. Desde su arquitectura colonial hasta sus festividades vibrantes, cada rincón del pueblo es una invitación a conocer y disfrutar de su legado artístico y cultural. Ya sea que te interese la historia, el arte o las costumbres locales, Valle de Bravo tiene algo que ofrecer a todos los viajeros. </p>
      <br/>
     <p><b>¡Ven y sumérgete en la cultura de este maravilloso destino!</b></p>
      </div>
    </div>
  );
}

export default Articulo3Blog;
