import React, { useState, useEffect } from "react";
import "./reloj_style.css";

const Reloj = () => {
  const [horaActual, setHoraActual] = useState(new Date());

  useEffect(() => {
    const intervalo = setInterval(() => {
      setHoraActual(new Date());
    }, 1000); // Actualiza cada segundo

    return () => clearInterval(intervalo); // Limpia el intervalo al desmontar
  }, []);

  const formatearHora = horaActual.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const formatearFecha = horaActual.toLocaleDateString("es-ES", {
    weekday: "short", // Día de la semana
    month: "short", // Mes completo
    day: "numeric", // Día del mes
  });

  return (
    <div className="reloj">
      <p>{formatearFecha}  {formatearHora}</p>
    </div>
  );
};

export default Reloj;
