import "./AboutUs_style.css";
import Secc2_AboutUs from "../componentes/secc2_AboutUs/secc2_AU";
import CapturaCorreo_AU from "../componentes/Captura_correo/capturaCorreo_AU";
import GaleriaAU from "../componentes/secc2_AboutUs/galeriaAU";

function AboutUS() {
  return (
    <div className="paginaAU">
      <div className="barra_paginaAU"></div>
      <div className="AboutUs_secc1">
        <div className="contendio_secc1">
          <div className="textoContenido_secc1">
            <h1 className="textoBlanco_secc1">ACERCA DE NOSOTROS</h1>
            <p className="textoBlancop_secc1">
              Ubicados en el corazón de Valle de Bravo, somos más que un hotel:
              somos un punto de encuentro para quienes buscan comodidad,
              hospitalidad y proximidad a las principales atracciones del
              destino. Nuestro compromiso es hacer que cada huésped se sienta
              como en casa, ofreciendo una experiencia inolvidable en un
              ambiente cálido y acogedor.
            </p>
            <h2>Nuestra misión</h2>
            <p>
              Con una combinación de tradición y modernidad, hemos creado un
              espacio que refleja la esencia de Valle de Bravo, desde el diseño
              de nuestras habitaciones hasta el trato personalizado de nuestro
              equipo. Creemos en la importancia de cada detalle para garantizar
              tu satisfacción.
              <br />
              <br />
              Brindar una experiencia de hospedaje que supere las expectativas
              de nuestros huéspedes, combinando calidad, comodidad y un servicio
              excepcional. Nos apasiona ser parte de tus recuerdos, creando
              momentos únicos que marcarán tu estancia en Valle de Bravo.
            </p>
          </div>
          <div className="enlacesContenido_secc1">
            <div className="enlace_secc1AU">
              <a href="https://wa.me/527224043015"
              target="blank"
              >
                <img
                  className="icono_enlace1_secc1AU"
                  src="./imagenes/iconos/whatsapp_verde.svg"
                  alt="WhatsApp"
                ></img>
              </a>
              <p>WhatsApp</p>
            </div>
            <div className="enlace_secc1AU">
              <a href="mailto:contacto@hotelrealsanagustin.com"
              target="blank"
              >
                <img
                  className="icono_enlace1_secc1AU"
                  src="./imagenes/iconos/correo_verde.svg"
                  alt="Correo"
                ></img>
              </a>
              <p>Correo</p>
            </div>
            <div className="enlace_secc1AU">
              <a href="https://www.google.com/maps/place/HOTEL+REAL+SAN+AGUST%C3%8DN/data=!4m2!3m1!1s0x0:0x9c3bb2339cbe6c8d?sa=X&ved=1t:2428&ictx=111"
              target="blank"
              >
                <img
                  className="icono_enlace1_secc1AU"
                  src="./imagenes/iconos/ubicacion_verde.svg"
                  alt="Ubicación"
                ></img>
              </a>
              <p>
                Centro,
                <br /> Valle de Bravo
              </p>
            </div>
          </div>
        </div>
        <div className="imagen_cont_secc1">
          <img
            className="imagenAU1"
            src="./imagenes/portalAU.svg"
            alt="Sala Hotel "
          ></img>
        </div>
      </div>
      <Secc2_AboutUs />
      <GaleriaAU/>
      <CapturaCorreo_AU />
     
    </div>
  );
}
export default AboutUS;
