import "./galeria_style.css"

import React, { useState } from "react";

const GaleriaPrincipal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openPopup = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
    setCurrentImage("");
  };

  const images = [
    "./imagenes/galeria/462149205_1299055804582886_1006545949177207373_n.jpg",
    "./imagenes/galeria/468094122_17888866635103322_6140960946205435044_n.jpg",
    "./imagenes/galeria/467815715_17888866656103322_251042203222786641_n.jpg",
    "./imagenes/galeria/468266312_17888866638103322_1694970362223084399_n.jpg",
    "./imagenes/galeria/469703372_1348470862974713_3374909509485950929_n.jpg",
    "./imagenes/galeria/469609018_1348470809641385_8966969374341621469_n.jpg",
    "./imagenes/galeria/469712105_1348470846308048_4609050188809166669_n.jpg",
    "./imagenes/galeria/469711966_1348470842974715_6858672826999435112_n.jpg",
  ];

  return (
    <div className="galeriaPrincipal_cont">
      <h2>GALERÍA FOTOGRÁFICA</h2>
      <div className="contImagenes">
        {images.map((src, index) => (
          <img
            key={index}
            className="fotoGaleria_home"
            src={src}
            alt={`Foto ${index + 1}`}
            onClick={() => openPopup(src)}
          />
        ))}
      </div>
      {isOpen && (
        <div className="popup" onClick={closePopup}>
          <div className="popupContent" onClick={(e) => e.stopPropagation()}>
            <img src={currentImage} alt="Imagen ampliada" />
            <a className="botonCerrar_Galeria" onClick={closePopup}>
              X
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default GaleriaPrincipal;
