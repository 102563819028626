import "./articuloBlog_style.css";

function Articulo4Blog() {
  return (
    <div className="cont_ArticuloBlog">
      <div className="barra_paginaBlog">
        <h1>Blog</h1>
        <p>
          Valle de Bravo no solo es un destino turístico por sus paisajes y
          actividades al aire libre, sino también por su rica y variada oferta
          gastronómica. Desde comida tradicional mexicana hasta propuestas
          internacionales y gourmet, este encantador pueblo tiene algo para
          complacer a cada paladar. A continuación, te presentamos una selección
          de algunos de los mejores restaurantes en Valle de Bravo que no puedes
          dejar de visitar.
        </p>
      </div>
      <div className="encabezado_articulo encArt4">
        <h1>
          Guía de Restaurantes en Valle de Bravo: Sabores para Todos los Gustos
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          dictum sagittis tortor, sit amet auctor urna. Mauris egestas consequat
          suscipit.
        </p>
      </div>
      <hr />
      <div className="seccion1Articulo">
        <div className="texto1Articulo">
          <h3>1. Restaurante "Chile Mole Pozole"</h3>
          <p>
            Si buscas auténtica comida mexicana, Chile Mole Pozole es una parada
            obligada. Este restaurante es conocido por sus platillos
            tradicionales, preparados con recetas caseras y servidos con ese
            toque cálido y sabroso que caracteriza a la gastronomía mexicana.
            Aquí puedes disfrutar de sopes, enchiladas, pozole y, por supuesto,
            un delicioso mole que te hará sentir como en casa.
          </p>
          <ul>
            <li>
              <b>Especialidad:</b>
              Mole casero y pozole.
            </li>
            <li>
              <b>Ambiente:</b>
              Familiar y acogedor.
            </li>
            <li>
              <b>Tip:</b>
              Acompaña tu comida con una agua fresca de sabores naturales.
            </li>
          </ul>
          <h3>2. Da Ciro - Comida Italiana</h3>
          <p>
            Para los amantes de la gastronomía italiana, Da Ciro es una
            excelente opción. Ubicado en Avándaro, este restaurante ofrece
            pastas frescas, pizzas al horno de leña y auténticos sabores
            italianos. El ambiente es acogedor y perfecto tanto para una comida
            familiar como para una cena romántica.
          </p>
          <ul>
            <li>
              <b>Especialidad:</b>
              Pizza Margarita y lasaña de carne.
            </li>
            <li>
              <b>Ambiente:</b>
              Rústico y elegante.
            </li>
            <li>
              <b>Tip:</b>
              No te pierdas el tiramisú casero para cerrar con broche de oro.
            </li>
          </ul>
        </div>
        <div className="imagenArticulo_cont">
          <img
            src="https://www.momadvisor.com.mx/wp-content/uploads/2019/12/da-ciros-valle-de-bravo-restaurante-italiano-para-ir-con-ninos-ni%C3%B1os-mexico-pizza-1.png"
            className="imagenArticulo"
            alt="Restaurante Da Ciro, Avándaro."
          ></img>
          <p className="piedeimagenArticulo">
            Restaurante Da Ciro, Avándaro.
          </p>
        </div>
      </div>
      <h2 className="subtituloArticulo">Nombre 2 del articulo</h2>
      <div className="seccion2Articulo">
        <div className="imagenArticulo_cont iac2">
          <img
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/MERCADO%20MUNICIPAL_0.jpg"
            className="imagenArticulo"
            alt="Mercado municipal de Valle de Bravo."
          ></img>
          <p className="piedeimagenArticulo">
            Mercado municipal de Valle de Bravo.
          </p>
        </div>
        <div className="texto1Articulo">
          <h3>3. Dipao - Fusión Italiana y Mexicana</h3>
          <p>
            Dipao combina lo mejor de la cocina italiana y mexicana con un toque
            contemporáneo. Sus platillos destacan por la creatividad en la
            presentación y la calidad de los ingredientes. Es el lugar perfecto
            para disfrutar de una comida en un ambiente relajado, con vistas al
            lago o los jardines del restaurante.
          </p>
          <ul>
            <li>
              <b>Especialidad:</b>
              Risotto de hongos y alcachofa.
            </li>
            <li>
              <b>Ambiente:</b>
              Relajado y con vistas naturales.
            </li>
            <li>
              <b>Tip:</b>
              Reserva con anticipación si planeas visitar el restaurante en fin
              de semana.
            </li>
          </ul>
          <h3>4.Mercado Municipal</h3>
          <p>
            Si quieres una experiencia más casual y económica, el mercado de
            comida en el centro de Valle de Bravo es ideal. Aquí encontrarás
            antojitos mexicanos como quesadillas, tacos, tamales y otros
            platillos típicos preparados al momento. Además, el ambiente local
            le da un encanto especial a la experiencia.
          </p>
          <ul>
            <li>
              <b>Especialidad:</b>
              Quesadillas y tacos de barbacoa.
            </li>
            <li>
              <b>Ambiente:</b>
              Local y auténtico.
            </li>
            <li>
              <b>Tip:</b>
              Lleva efectivo, ya que no todos los puestos aceptan tarjeta.
            </li>
          </ul>
        </div>
      </div>
      <div className="texto_adicional">
        <h3>5.Restaurante "Los Pericos" - Comida Frente al Lago</h3>
        <p>
          Ubicado a orillas del lago, Los Pericos es uno de los restaurantes más
          conocidos de Valle de Bravo. Ofrece una combinación de mariscos
          frescos, cortes de carne y opciones de comida mexicana. Lo mejor de
          este lugar es la vista panorámica del lago, que crea un ambiente
          relajado y romántico.
        </p>
        <p>Valle de Bravo es un verdadero festín para los sentidos, con opciones gastronómicas que van desde la comida tradicional hasta propuestas internacionales de alta calidad. Ya sea que busques una experiencia auténtica en un mercado local, una cena romántica frente al lago o platillos gourmet, este destino tiene algo especial para cada gusto. <b>¡Ven a descubrir los sabores que Valle de Bravo tiene para ofrecer!</b></p>
      </div>
    </div>
  );
}

export default Articulo4Blog;
