import { useState, useEffect } from "react";
import { db, collection, query, where, getDocs} from "../firebase"; // Importando Firebase
import {Timestamp } from "firebase/firestore"
import "./counterDashboard_style.css";

function ContadorHuespedes() {
  const [checkIns, setCheckIns] = useState(0);
  const [checkOuts, setCheckOuts] = useState(0);
  const [ocupadas, setOcupadas] = useState(0);
  const [libres, setLibres] = useState(20)

  // Función para obtener el número de check-ins y check-outs de los últimos 7 días
  const obtenerDatosContador = async () => {
    // Fecha actual y hace 7 días
    const hoy = new Date();
    const hace7Dias = new Date(hoy);
    hace7Dias.setDate(hoy.getDate() - 8); // Ajustamos correctamente para los últimos 7 días

    // Convertimos hace7Dias a un Timestamp de Firebase
    const hace7DiasTimestamp = Timestamp.fromDate(hace7Dias);

    try {
      // Consulta para obtener check-ins de los últimos 7 días
      const checkInsQuery = query(
        collection(db, "checkins"), // Asegúrate de que "checkIns" sea la colección correcta
        where("timestamp", ">=", hace7DiasTimestamp) // Filtramos por fecha usando Timestamp
      );

      // Consulta para obtener check-outs de los últimos 7 días
      const checkOutsQuery = query(
        collection(db, "checkouts"), // Asegúrate de que "checkOuts" sea la colección correcta
        where("timestamp", ">=", hace7DiasTimestamp) // Filtramos por fecha usando Timestamp
      );

      // Obtener los datos de check-ins
      const checkInsSnapshot = await getDocs(checkInsQuery);
      setCheckIns(checkInsSnapshot.size); // Seteamos el número de check-ins

           // Obtener los datos de check-outs
           const checkOutsSnapshot = await getDocs(checkOutsQuery);
           setCheckOuts(checkOutsSnapshot.size); // Seteamos el número de check-outs
     
           // Calcular habitaciones ocupadas
           const habitacionesOcupadas = checkInsSnapshot.size - checkOutsSnapshot.size;
           setOcupadas(habitacionesOcupadas > 0 ? habitacionesOcupadas : 0); // Validar que no sea negativo
     
           // Calcular habitaciones libres
           const totalHabitaciones = 20;
           setLibres(totalHabitaciones - (habitacionesOcupadas > 0 ? habitacionesOcupadas : 0));
         } catch (error) {
           console.error("Error obteniendo los datos de Firestore: ", error);
         }
    
  };

  useEffect(() => {
    obtenerDatosContador();
  }, []); // Se ejecutará una sola vez al montar el componente

  return (
    <div className="seccion_ContadorHusespedes">
      <div className="card_Contador card_Verde">
        <a>
          <img src="./imagenes/iconos/checkin_verde.svg" className="icono_Contador" alt="Check In Icon" />
          <h2>Check In</h2>
        </a>
        <h2 className="tg_Contador">{checkIns}</h2> {/* Mostramos el contador de check-ins */}
        <button 
          className="info-btn" 
          title="Número de check-ins realizados en la última semana"
        >
          i
        </button>
      </div>

      <div className="card_Contador card_Rojo">
        <a>
          <img src="./imagenes/iconos/checkout_rojo.svg" className="icono_Contador" alt="Check Out Icon" />
          <h2>Check Out</h2>
        </a>
        <h2 className="tg_Contador">{checkOuts}</h2> {/* Mostramos el contador de check-outs */}
        <button 
          className="info-btn" 
          title="Número de check-outs contados en la última semana"
        >
          i
        </button>
      </div>

      <div className="card_Contador card_Azul">
        <a>
          <img src="./imagenes/iconos/ocupadas_azul.svg" className="icono_Contador" alt="Ocupadas Icon" />
          <h2>Ocupadas</h2>
        </a>
        <h2 className="tg_Contador">{ocupadas}</h2>
        <button 
          className="info-btn" 
          title="Habitaciones ocupadas este día"
        >
          i
        </button>
      </div>

      <div className="card_Contador card_Amarillo">
        <a>
          <img src="./imagenes/iconos/disponibles_amarillo.svg" className="icono_Contador" alt="Disponibles Icon" />
          <h2>Disponibles</h2>
        </a>
        <h2 className="tg_Contador">{libres}</h2>
        <button 
          className="info-btn" 
          title="Habitaciones disponibles este día"
        >
          i
        </button>
      </div>
    </div>
  );
}

export default ContadorHuespedes;
