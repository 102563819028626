import "./capturaCorreo_AU_style.css";
import { useState } from "react";
import { db } from "../firebase"; // Ajusta la ruta según tu estructura
import { collection, addDoc } from "firebase/firestore";

function CapturaCorreo_Blog() {
  const [email, setEmail] = useState(""); // Estado para capturar el correo

  // Función para validar formato de correo
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para correos
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    if (!isValidEmail(email)) {
      alert("Por favor, ingresa un correo válido.");
      return;
    }

    try {
      await addDoc(collection(db, "suscriptores_Blog"), { email });
      alert("¡Gracias por suscribirte!");
      setEmail(""); // Limpiar el campo
    } catch (error) {
      console.error("Error al guardar el correo: ", error);
      alert("Hubo un error al suscribirte. Intenta de nuevo.");
    }
  };

  return (
    <div className="capturaCorreoAU_cont">
      <div className="CapcorrAU_secc1">
        <h2>SUSCRIBETE PARA RECIBIR LAS NOVEDADES DE VALLE DE BRAVO</h2>
        <p>
          Registra tu correo y recibe las mejores ofertas y sugerencias para
          visitar Valle de.{" "}
        </p>
      </div>
      <div className="CapcorrAU_secc">
        <input
          type="email"
          placeholder="Ingresa tu correo"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="textfield_CapcorrAU"
        ></input>
        <button className="boton_CapcorrAU" onClick={handleSubscribe}>Suscribirme</button>
      </div>
    </div>
  );
}

export default CapturaCorreo_Blog;
