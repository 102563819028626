import "./footer_style.css";
import { Link } from "react-router-dom";

function PieDepagina() {
  return (
    <div className="footer">
      <section className="secc1_footer">
        <img
          className="logo_footer"
          src="./imagenes/logosanagustin_verde.svg"
          alt="Hotel Real San Agustín"
        ></img>
        <a>
        <p>
          Vive la comodidad de estar a unos pasos de todo lo mejor de Valle de
          Bravo.
        </p>
        <div className="rs_footer">
          <Link
            to="https://www.facebook.com/HotelRealSanAgustinValleDeBravo/?locale=es_LA"
            target="blank"
          >
            <img
              className="logors_footer"
              src="./imagenes/iconos/iconoverdeing.svg"
              alt="instagram"
            ></img>
          </Link>
          <Link
            to="https://www.instagram.com/hotel.realsanagustin/"
            target="blank"
          >
            <img
              className="logors_footer"
              src="./imagenes/iconos/iconoverdefb.svg"
              alt="facebook"
            ></img>
          </Link>
        </div>
        </a>
      
      </section>
      <hr className="separadorFooter"/>
      <section className="secc2_footer">
        <div className="contacto_footer">
          <h4>Contacto</h4>
          <div className="opcioncontacto_footer">
            <img
              className="iconocontacto_footer"
              src="./imagenes/iconos/ubicacion_verde.svg"
              alt="Ubicacion"
            ></img>
            <a href="https://maps.app.goo.gl/zme2NP6LVUJWkfSW9" target="blank">
              <p className="texto_contactofooter">
                C. Francisco González Bocanegra 103, Centro, 51200 Valle de
                Bravo, Méx.
              </p>
            </a>
          </div>
          <div className="opcioncontacto_footer">
            <img
              className="iconocontacto_footer"
              src="./imagenes/iconos/telefono_verde.svg"
              alt="Telefono"
            ></img>
            <a href="tel:+52726 262 0824">
              <p className="texto_contactofooter">726 262 0824</p>
            </a>
          </div>
          <div className="opcioncontacto_footer">
            <img
              className="iconocontacto_footer"
              src="./imagenes/iconos/whatsapp_verde.svg"
              alt="WhatsApp"
            ></img>
            <a href="http://wa.me/527224043015" target="blank">
              <p className="texto_contactofooter">
                7224043015
              </p>
            </a>
          </div>
          <div className="opcioncontacto_footer">
            <img
              className="iconocontacto_footer"
              src="./imagenes/iconos/correo_verde.svg"
              alt="Correo"
            ></img>
            <a href="mailto:contacto@hotelsanagustin.com" target="blank">
              <p className="texto_contactofooter">
                contacto@hotelrealsanagustín.com
              </p>
            </a>
          </div>
         
        </div>
        <div className="secciones_footer">
          <h4>Secciones</h4>
          <ul>
            <Link to="/">
              <li>Inicio</li>
            </Link>
            <Link to="/Blog">
              <li>Blog</li>
            </Link>
            <Link to="/">
              <li>Galería</li>
            </Link>
            <Link to="AboutUs">
              <li>About Us</li>
            </Link>
          </ul>
        </div>
        <div className="informacion_footer">
          <h4>Información</h4>
          <ul>
            <li>Politica de privacidad</li>
          </ul>
        </div>
      </section>
      <section className="SeccPriv_footer">
        <a href="/login">
          <img
            className="iconoLlaver"
            src="./imagenes/iconos/llaveentrada.svg"
            alt=""
          ></img>
        </a>
      </section>
    </div>
  );
}

export default PieDepagina;
