import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./secc2_AU_style.css";
import { db } from "../firebase"; // Asegúrate de ajustar la ruta
import { collection, addDoc } from "firebase/firestore";

function Secc2_AboutUs() {
  const [formData, setFormData] = useState({
    nombre: "",
    telefonoCorreo: "",
    mensaje: "",
  });

  const [errors, setErrors] = useState({});

  const validateInput = () => {
    const newErrors = {};

    // Validar nombre
    if (!formData.nombre.trim()) {
      newErrors.nombre = "El nombre es obligatorio.";
    }

    // Validar teléfono o correo
    const telefonoRegex = /^[0-9]{10}$/;
    const correoRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.telefonoCorreo.trim()) {
      newErrors.telefonoCorreo = "El teléfono o correo es obligatorio.";
    } else if (
      !telefonoRegex.test(formData.telefonoCorreo) &&
      !correoRegex.test(formData.telefonoCorreo)
    ) {
      newErrors.telefonoCorreo =
        "Debe ingresar un número de teléfono válido o un correo electrónico válido.";
    }

    // Validar mensaje
    if (!formData.mensaje.trim()) {
      newErrors.mensaje = "El mensaje es obligatorio.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      try {
        await addDoc(collection(db, "contactos_AU"), formData); // Guardar en Firestore
        alert("Formulario enviado con éxito.");
        setFormData({
          nombre: "",
          telefonoCorreo: "",
          mensaje: "",
        });
        setErrors({});
      } catch (error) {
        console.error("Error al guardar el mensaje: ", error);
        alert("Hubo un error al enviar tu mensaje. Intenta de nuevo.");
      }
    }
  };

  return (
    <section className="secc2_AboutUs">
      <div className="formulario_secc2AU" id="contacto">
        <h2>PONTE EN CONTACTO</h2>
        <p>
          Nos encantaría saber de ti. Ya sea para resolver tus dudas, ayudarte a
          planificar tu visita o recibir tus comentarios, estamos aquí para ti.
          Nuestro equipo está disponible en todo momento para asegurarse de que
          tu experiencia sea perfecta.
        </p>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              className="input_formularioAU1"
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={formData.nombre}
              onChange={handleInputChange}
            />
            {errors.nombre && <p className="error">{errors.nombre}</p>}
          </div>
          <div>
            <input
              className="input_formularioAU1"
              type="text"
              name="telefonoCorreo"
              placeholder="Teléfono o Correo Electrónico"
              value={formData.telefonoCorreo}
              onChange={handleInputChange}
            />
            {errors.telefonoCorreo && (
              <p className="error">{errors.telefonoCorreo}</p>
            )}
          </div>
          <div>
            <input
              className="input_formularioAU1 input_mensajeAU1"
              type="text"
              name="mensaje"
              placeholder="Escribe tu mensaje"
              value={formData.mensaje}
              onChange={handleInputChange}
            />
            {errors.mensaje && <p className="error">{errors.mensaje}</p>}
          </div>
          <button className="boton_formularioAU" id="au_button" type="submit">
            <h4>Enviar mensaje</h4>
          </button>
        </form>
      </div>
      <div className="informacionsecc2AU">
        <h2>Nuestra ubicación</h2>
        <p>
          Descubre la comodidad de hospedarte en el corazón de Valle de Bravo,
          donde cada rincón del pueblo mágico está a tu alcance. Desde
          pintorescas calles empedradas hasta restaurantes y atracciones
          turísticas, todo comienza aquí.
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.0309728246!2d-100.13097959999999!3d19.1938495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd63ff0475c557%3A0x9c3bb2339cbe6c8d!2sHOTEL%20REAL%20SAN%20AGUST%C3%8DN!5e0!3m2!1ses!2smx!4v1734029382991!5m2!1ses!2smx"
          width="540"
          height="350"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <h3>REDES SOCIALES</h3>
        <div className="cont_rsAU">
          <Link
            to="https://www.instagram.com/hotel.realsanagustin/"
            target="blank"
          >
            <img
              className="iconorsAU"
              src="./imagenes/icono_IG.png"
              alt="Instagram"
            />
          </Link>
          <Link to="https://www.facebook.com/HotelRealSanAgustinValleDeBravo/?locale=es_LA" target="blank">
            <img
              className="iconorsAU"
              src="./imagenes/iconofb.png"
              alt="Facebook"
            />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Secc2_AboutUs;
