import "./blog_style.css";

import { Link } from "react-router-dom";
import CapturaCorreo_Blog from "../componentes/Captura_correo/capturaCorreo_Blog";

function Blog() {
  return (
    <div className="paginaAU">
      <div className="barra_paginaBlog">
        <h1>BLOG</h1>
        <p>
          Bienvenidos al blog de Hotel Real San Agustín, donde te llevamos a
          explorar los rincones más encantadores de Valle de Bravo. Desde
          paisajes naturales impresionantes hasta actividades llenas de aventura
          y cultura.
        </p>
      </div>

      <div className="principalesBlog_cont">
        <div className="articulo_principal artPrinc1">
          <div className="texto_artPrinc">
            <h3>Los Mejores Sitios para Ver el Atardecer en Valle de Bravo</h3>
            <p>
              Un recorrido por los lugares más impresionantes para disfrutar de
              los atardeceres en Valle de Bravo, incluyendo miradores, el lago,
              y algunos rincones tranquilos para una experiencia única.
            </p>
          </div>
          <div className="botonArtPrinc_cont">
            <Link to="/Blog/Articulo1">
              <img
                className="flechaArtPrinc"
                src="./imagenes/iconos/flecha1.svg"
                alt="Ver más"
              ></img>
            </Link>
          </div>
        </div>
        <div className="articulo_principal artPrinc2">
          <div className="texto_artPrinc">
            <h3>
              Actividades al Aire Libre en Valle de Bravo: Aventura y Naturaleza
            </h3>
            <p>
              Un artículo sobre actividades como senderismo, paseos en lancha,
              ciclismo de montaña y deportes acuáticos, destacando cómo los
              visitantes pueden disfrutar de la naturaleza en Valle de Bravo.
            </p>
          </div>
          <div className="botonArtPrinc_cont">
            <Link to="/Blog/Articulo2">
              <img
                className="flechaArtPrinc"
                src="./imagenes/iconos/flecha1.svg"
                alt="Ver más"
              ></img>
            </Link>
          </div>
        </div>
      </div>
      <hr className="separadorBlogPrincipal" />
      <div className="articulosSecundarios">
        <div className="articuloSecundario ">
          <img
            className="imagenArtSecundario"
            src="https://mexicoxdescubrir.com/wp-content/uploads/2023/03/Museo-arte-popular-Valle-de-Bravo-estado-Mexico-1-1024x576.jpg.webp"
            alt=""
          />
          <a>
          <h3>
            Visita Cultural en Valle de Bravo: Arte, Historia y Tradiciones
            Locales
          </h3>
          <p>
            Un recorrido por los principales puntos culturales de Valle de
            Bravo, como la Parroquia de San Francisco de Asís, el Museo de Arte
            de Valle de Bravo y las festividades locales.
          </p>
          <Link to="/Blog/Articulo3">
          <button className="botonArtSecundario">VER</button>
          </Link>
          </a>
          
        </div>

        <div className="articuloSecundario ">
          <img
            className="imagenArtSecundario"
            src="https://www.travelreport.mx/wp-content/uploads/2018/11/gastronomia-de-valle-de-bravo-1-e1583931966561.jpg"
            alt=""
          />
          <a>
          <h3>
            Descubre la Gastronomía de Valle de Bravo: Restaurantes y Platillos
            Imperdibles
          </h3>
          <p>
            Una guía sobre los mejores restaurantes de Valle de Bravo, con
            énfasis en la comida local y platillos típicos como el mole, el
            pozole, y las tlayudas, recomendando lugares cercanos al hotel.
          </p>
          <Link to="/Blog/Articulo4">
          <button className="botonArtSecundario">VER</button>
          </Link>
          </a>
        
        </div>

        <div className="articuloSecundario ">
          <img
            className="imagenArtSecundario"
            src="https://cdn0.bodas.com.mx/article-vendor-o/4516/3_2/960/jpg/dsc-6334_5_144516.jpeg"
            alt=""
          />
          <a>
          <h3>
            Escapadas Románticas en Valle de Bravo: El Lugar Ideal para una
            Pareja
          </h3>
          <p>
            Un artículo dirigido a parejas, con sugerencias sobre actividades
            románticas, desde cenas a la orilla del lago hasta paseos privados y
            actividades relajantes en el hotel.
          </p>
          <Link to="/Blog/Articulo5">
          <button className="botonArtSecundario">VER</button>
          </Link>
          </a>
       
        </div>
      </div>
      <CapturaCorreo_Blog />
    </div>
  );
}
export default Blog;
